import { useCallback, useEffect, useMemo } from "react"
import {
  readChannelAccounts,
  upsertChannelAccount,
  IChannelStorageItem,
  removeChannelAccount,
  switchTo
} from "./multi-channel.utils";
import useUser from "../../../hooks/useUser";
import { useQueryClient } from "react-query";
import { setChannelToken } from "../../../Utils";
import { LocalStorageKeys } from "../../../Interfaces";


export const useChannelAccounts = (changeDet: boolean | undefined) => {

  const { channelInfo, channelToken } = useUser();
  const queryClient = useQueryClient();

  useEffect(() => {
    // save current to list
    if (channelInfo?.id) {
      upsertChannelAccount({
        id: channelInfo.id,
        avatar: channelInfo.avatar as string,
        name: channelInfo.name || '',
        token: channelToken || '',
        active: true,
      })
    }
  }, [channelInfo, channelToken, changeDet]);

  // eslint-disable-next-line
  const accounts = useMemo(() => readChannelAccounts(), [changeDet]);
  const accountsList = useMemo(() => Object.values(accounts).filter(acc => !acc.active), [accounts]);
  const upsertAccount = useCallback((act: IChannelStorageItem) => upsertChannelAccount(act), []);
  const switchToCh = useCallback((channel: IChannelStorageItem) => {
    switchTo(channel);
    setChannelToken(channel.token);
    queryClient.clear();
    queryClient.refetchQueries();
  }, [queryClient]);
  const logout = useCallback(() => {
    if (channelInfo?.id) {
      const nextAccount = removeChannelAccount(channelInfo?.id);
      if (!!nextAccount) {
        // has another accounts
        localStorage.removeItem(LocalStorageKeys.ChannelToken);
        setChannelToken(nextAccount.token);
        switchTo(nextAccount);
        queryClient.clear();
        queryClient.refetchQueries(); // refetch all queries:
        document.location.reload();
      } else {
        // false means there is no account remins.
        localStorage.removeItem(LocalStorageKeys.ChannelToken);
        queryClient.clear();
        document.location.reload();
      }
    }
    // eslint-disable-next-line
  }, [removeChannelAccount, queryClient, channelInfo]);


  return {
    accounts,
    accountsList,
    upsertAccount,
    logout,
    switchTo: switchToCh
  }

}