import React, { useEffect, useState } from "react";
import { ThemeStates } from "../../api/interfaces";
import { useTheme } from "./useTheme";



export const ThemeProvider: React.FC = ({children}) => {

  const { theme } = useTheme();
  const [fUpdate, update] = useState(0);

  useEffect(() => {
    if (theme === ThemeStates.Dark) {
      setTimeout(() => {
        document.documentElement.setAttribute('dark', 'true');
        update(s => s + 1);
      });
    } else if(theme === ThemeStates.Light) {
      setTimeout(() => {
        document.documentElement.setAttribute('dark', 'false');
        update(s => s + 1);
      });
    }
  }, [theme, update]);

  return <React.Fragment key={fUpdate}>{children}</React.Fragment>;
}