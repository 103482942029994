import { useQuery } from "react-query"
import useUser from "../../hooks/useUser"
import { getCompanyCustomConfig } from "../../api/fetchers/Channel";


export const useConfigData = () => {

  const { channelToken } = useUser();
  const q = useQuery(
    ['CHANNEL_CUSTOM_CONFIG'],
    () => getCompanyCustomConfig(),
    {
      enabled: !!channelToken,
      cacheTime: 60000 * 30 // 30 min = 60s * 30
    }
  )

  return {
    ...q
  }

}