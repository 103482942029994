import { HTMLAttributes, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  type: `SIMPLE` | `FILL` | `PALE` | 'IN_TEXT';
  isHover?: boolean;
  to: string;
  className?: string;
}

/**
 * Primary UI component for user interaction
 */
export const YtLink = ({
  children,
  to,
  isHover = true,
  ...props
}: LinkProps) => {
  return (
    <Container to={to} isHover={isHover} {...props}>
      {children}
    </Container>
  );
};

const Container = styled(Link)<{
  type: `SIMPLE` | `FILL` | `PALE` | 'IN_TEXT';
  isHover: boolean;
  to: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  width: auto;
  height: 3.5rem;
  border-radius: 12px;
  position: relative;
  ${({ isHover }) =>
    isHover &&
    `:hover {
    color: var(--color-primary-dark);
  }`}

  ${({ type }) =>
    type === `SIMPLE` &&
    `color:var(--color-primary);
letter-spacing: 0.001em;
text-decoration-line: underline;
font-weight: bold;
font-size: 14px;
line-height: 26px;
  height: auto;
`}

  ${({ type }) =>
    type === 'FILL' &&
    `background-color: var(--color-primary-light2);
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  border: 1px solid #FF999A;
box-sizing: border-box;
    padding: 1rem;
  `}
  ${({ type }) =>
    type === 'PALE' &&
    `color: var(--color-primary-light1);
  font-weight: 600;
font-size: 16px;
line-height: 20px;`}
  ${({ type }) =>
    type === 'IN_TEXT' &&
    `color: var(--color-third);
  font-weight: 600;
font-size: 16px;
line-height: 20px;
height: auto;
border-bottom: 1px solid;
    border-radius: 0;
`}

  //////////////active///////////////////////
  &:active {
    transform: scale(0.975);
  }
  /////////////underline hover//////////
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    right: 0;

    background-color: ${({ color }) => color};
    transition: all 0.3s ease;
  }
  &:hover {
    :before {
      width: 100%;
      left: 0;
    }
    color: ${({ color }) => color};
  }
`;
