import { HTMLAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Body16Style } from '../../../Elements/Typo';
import {
  hoverLineState1_inBefore,
  hoverLineState2_inBefore,
} from '../../../Styles/Animation';

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string;
  destination: string;
  className?: string;
  isActive?: boolean;
}

const PrimaryItem: React.FC<Props> = ({
  label,
  destination,
  className,
  isActive,
}) => {
  const location = useLocation();

  return (
    <Container className={className}>
      <Item
        to={destination}
        isActive={
          isActive !== undefined ? isActive : location.pathname === destination
        }
      >
        {label}
      </Item>
    </Container>
  );
};
export default PrimaryItem;
const Container = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  margin-right: 1rem;
  transition: border 0.1s linear;
  cursor: pointer;
  border-right: 1px solid var(--color-gray5);
`;
const Item = styled(Link)<{ isActive: boolean }>`
  ${Body16Style};
  margin-right: 1.5rem;
  margin-top: 4px;
  padding-right: 0.5rem;
  color: var(--color-primary);
  cursor: pointer;
  ///////active with url//////////
  ${({ isActive }) => isActive && 'color: var(--color-primary-dark);'};
  position: relative;
  &:before {
    ${hoverLineState1_inBefore}
  }
  &:hover {
    &:before {
      ${hoverLineState2_inBefore}
    }
  }
`;
