import { AxiosError } from "axios";
import ErrorToast from "../Elements/Toast/Error";
import { sendErrorLogToServer } from "../Utils";
import { SendErrorLogCode } from "./interfaces";

function AxiosErrorHandler(e: AxiosError, userEmail?: string) {
  try {
    const errStatus = e?.response?.status;
    if (errStatus === 422) {
      Object.values(e?.response?.data?.errors).map((error: unknown) => {
        ErrorToast(error as string);
      });
    } else if (errStatus === 451) {
      localStorage.clear();
      window.location.href = "/suspend";
    } else {
      const errMessage: string = e?.response?.data?.message;
      if (errMessage.includes("server error"))
        sendErrorLogToServer({
          message: `server error in ${errMessage}` as string,
          path: window?.location?.href + " in AxiosErrorHandler",
          trace: JSON.stringify(e),
          code: SendErrorLogCode.ServerError,
          email: userEmail,
        });
      ErrorToast(e?.response?.data?.message as string);
    }
  } catch (error) {
    ErrorToast("Something went wrong. code 421");
    sendErrorLogToServer({
      message: error as string,
      path: window?.location?.href,
      trace: JSON.stringify(e),
      code: SendErrorLogCode.AxiosErrorHandler,
      email: userEmail,
    });
  }
}
function isItOnLive(location?: string): boolean {
  const smartLocation = location || window.location.href;

  if (
    smartLocation.includes("https://ytjobs.co") ||
    smartLocation.includes("http://ytjobs.co") ||
    smartLocation.includes("https://www.ytjobs.co") ||
    smartLocation.includes("http://www.ytjobs.co")
  )
    return true;
  return false;
}
function isItOnBeta(location?: string): boolean {
  const smartLocation = location || window.location.href;

  if (smartLocation.includes("beta.ytjobs.co")) return true;
  return false;
}
function getSmartBaseUrl(location?: string) {
  const smartLocation = location || window.location.href;
  if (isItOnLive(smartLocation)) return "https://app.ytjobs.co/api";
  if (isItOnBeta(smartLocation)) return "https://app3.ytjobs.co/api";
  return "https://app2.ytjobs.co/api";
}

export { AxiosErrorHandler, getSmartBaseUrl, isItOnBeta, isItOnLive };
