import styled, { css } from "styled-components/macro";
import { hoverSmallAnimation } from "../../../Styles/Animation";
import { IoMailOpenOutline, IoMailUnreadOutline } from "react-icons/io5";
import Logo from "../../Logo";
import UserAvatar from "../../UserAvatar";
import SecondaryItem from "./SecondaryItem";
import { Body14Style } from "../../../Elements/Typo";
import ChannelThumbnail from "../../Channel/ChannelThumbnail";
import { device } from '../../../consts/device';
import Icon from '../../../Elements/YtIcons';
import { Link } from 'react-router-dom';
import { FiLogOut, FiUserPlus } from "react-icons/fi";

export const StyledLogo = styled(Logo)`
  height: 1.2rem;
  margin-right: 2.5rem;
`;
export const PagesWrapper = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const SignIn = styled(SecondaryItem)`
  color: var(--color-gray1);
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  margin-left: auto;
  margin-right: 0;
  * {
    padding-right: 0;
  }
  a {
    margin-top: 0 !important;
  }
`;
export const SignUp = styled(SecondaryItem)`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  padding: 4px 8px;
  background-color: var(--color-primary-light2);
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  height: 34px;
  margin: 0 0 0 12px;
  > a {
    margin: 0;
    color: var(--color-primary);
    &::before {
      content: unset;
    }
  }

`;
export const EndSection = styled.div`
  display: flex;
  position: relative;
  margin-left: auto;
  align-items: center;
`;
export const StyledUserAvatar = styled(UserAvatar)`
  cursor: pointer;
  filter: contrast(90%);
  div {
    &:hover {
      ${hoverSmallAnimation}
    }
  }
`;
export const AllReadMessageIcon = styled(IoMailOpenOutline)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-gray3);
  margin-right: 0.5rem;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-dark);
  }
`;
export const UnreadMessageIcon = styled(IoMailUnreadOutline)`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-primary);
  margin-right: 0.5rem;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-dark);
  }
`;
export const PopupContainer = styled.div`
  position: absolute;
  top: 2.7rem;
  right: 1.2rem;
  background-color: var(--color-bg1);
  border: 1px solid var(--color-gray4);
  z-index: 999999;
  box-shadow: -2px 2px 4px 0 rgba(0,0,0,0.3);
  border-radius: 6px;
`;
export const ThumbnailContainer = styled.div`
  padding: 1rem 3rem 1rem 1rem;
`;
export const LogoutContainer = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid var(--color-gray4);
  padding: 0.8rem 16px;
  cursor: pointer;
`;

export const LogoutContainer2 = styled(LogoutContainer)`
  border-top: 0;
  border-bottom: 1px solid var(--color-gray4);
`
export const LogoutContainer3 = styled(LogoutContainer)<{hasHover?: boolean}>`
  border-top: 0;
  ${({hasHover}) => hasHover && css`
    &:hover {
      cursor: pointer;
      background-color: var(--color-gray6);
    }
  `}
  > img {
    --avatar-account-size: 24px;
    min-height: var(--avatar-account-size);
    max-height: var(--avatar-account-size);
    min-width: var(--avatar-account-size);
    max-width: var(--avatar-account-size);
    border-radius: 50%;
  }
  @media ${device.mobileL} {
    justify-content: center;
  }
`
export const MobileOtherAccountsContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
  margin-top: -1rem;
  background-color: var(--color-gray6);
`

export const LogoutIcon = styled(FiLogOut)`
  color: var(--color-gray2);
`;
export const AddAccountIcon = styled(FiUserPlus)`
  color: var(--color-gray2);
`;

export const LogoutTitle = styled.h3`
  ${Body14Style};
  color: var(--color-gray1);
  margin-left: 0.5rem;
`;
export const StyledChannelThumbnail = styled(ChannelThumbnail)`
  #channel-name {
    white-space: nowrap;
  }
  #channel-subscriber {
    font-size: 10px;
    margin-top: 0.3rem;
  }
`;
export const TeammatesLink = styled(Link)`
  color: var(--color-primary);
  background-color: var(--color-gray6);
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 34px;
  border: 1px solid var(--color-primary);
  font-size: 14px;
  margin-right: 1rem;
  font-weight: 600;
  padding: 0 10px 0 16px;
  @media ${device.tabletS} {
    margin-right: 0;
  }
`;
export const ArrowRight = styled(Icon)`
  align-self: center !important;
  color: var(--color-primary) !important;
  fill: var(--color-primary) !important;
  width: 1rem !important;
  height: 1rem !important;
  @media ${device.tabletS} {
    margin-top: 2px;
  }
`;
export const MobileButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;
