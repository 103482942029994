import toast from 'react-hot-toast';
import { MdCheckCircle } from 'react-icons/md';
import styled from 'styled-components/macro';
import { isDarkMode } from '../../Utils';

function SuccessToast(message: string, duration?: number) {
  return toast.success(message, {
    style: {
      backgroundColor: isDarkMode() ? 'var(--color-success-dark)' :  'var(--color-gray2)',
      color: 'white',
      marginBottom: '2rem',
    },
    icon: <StyledMdCheckCircle />,
    duration: duration || 4000,
  });
}
export default SuccessToast;
const StyledMdCheckCircle = styled(MdCheckCircle)`
  color: var(--color-success);
`;
