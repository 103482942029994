import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import logoPlaceholder from '../../../../Assests/Images/logoPlaceholder';

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  isActive?: boolean;
  viewer?: 'OWNER' | 'OTHERS';
  avatar?: string | File | null;
  abvSubscribers: string;
  channelName: string;
  isClickable?: boolean;
  channelId?: string;
  isCompany?: boolean;
}
const Type4: React.FC<Props> = ({
  isClickable = false,
  isActive = false,
  viewer = 'OTHERS',
  avatar,
  abvSubscribers,
  channelName,
  channelId,
  isCompany,
  ...props
}) => {
  return (
    <Container
      to={isClickable && channelId ? `/channel/${channelId}` : ''}
      {...props}
    >
      <ChannelLogo
        isActive={isActive}
        viewer={viewer}
        src={typeof avatar === 'string' ? avatar : logoPlaceholder}
        alt={channelName || ''}
        loading='lazy'
      />
      {isCompany && (
        <SubsCompany>Company</SubsCompany>
      )}
      {!isCompany && (
        <Subs>{abvSubscribers || ''}</Subs>
      )}
    </Container>
  );
};
export default Type4;

const Container = styled(Link)`
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray4);
`;
const Subs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 25%;
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  background: var(--color-primary);
`;
const SubsCompany = styled(Subs)`
  background: var(--color-force-gray1);
  font-size: 8px;
  font-weight: 600;
  min-height: 13px;
`

const ChannelLogo = styled.img<{
  isActive: boolean;
  viewer: 'OWNER' | 'OTHERS';
}>`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  margin: 0.2rem 0.5rem;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.4s ease;

  ${({ isActive }) =>
    !isActive && 'filter: grayscale(1);'}
`;
