import React, { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { sendErrorLogToServer } from './Utils';

import { Content } from './Components/Errors';
import { SendErrorLogCode } from './api/interfaces';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    console.log(' error=', _);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sendErrorLogToServer({
      message: error.message,
      trace: errorInfo.componentStack,
      code: SendErrorLogCode.ErrorBoundary,
    });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorSection />;
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
const ErrorSection = styled(Content)`
  margin: auto;
`;
