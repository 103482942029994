import styled from 'styled-components/macro';
import WithTarget from './WithTarget';
import { Title } from './StyledComponents';
import { SmartBannerTypes } from './interface';
import useUser from '../../hooks/useUser';
import { device } from '../../consts/device';

interface Props {
  variant: SmartBannerTypes;
  teammateNumbers: number;
}
const SmartBanner: React.FC<Props> = ({ variant, teammateNumbers }) => {
  const { talentInfo } = useUser();

  const clickTeammateBanner = () => {
    try {
      // @ts-ignore
      ga('send', {
        hitType: 'event',
        eventCategory: 'Banners',
        eventAction: 'teammate-banner-clicked',
        eventLabel: 'Teammate verification banner clicked',
        value: 1
      });
    } catch (e) {
      console.log(e);
    }
  };
  const clickVerifyBanner = () => {
    try {
      // @ts-ignore
      ga('send', {
        hitType: 'event',
        eventCategory: 'Banners',
        eventAction: 'channel-verification-banner-clicked',
        eventLabel: 'Channel verification banner clicked',
        value: 1
      });
    } catch (e) {
      console.log(e);
    }
  };
  const clickChangeEmailBanner = () => {
    try {
      // @ts-ignore
      ga('send', {
        hitType: 'event',
        eventCategory: 'Banners',
        eventAction: 'channel-change-email-banner-clicked',
        eventLabel: 'Channel change email banner clicked',
        value: 1
      });
    } catch (e) {
      console.log(e);
    }
  };

  function getSmartContent() {
    switch (variant) {
      case SmartBannerTypes.COMPLETE_PROFILE:
        if (talentInfo?.id)
          return (
            <WithTarget target={`/talent/profile/${talentInfo.id}`}>
              <Title>Please complete your profile!</Title>
            </WithTarget>
          );
        else return null;
      case SmartBannerTypes.ASK_FOR_REVIEW:
        if (talentInfo?.id)
          return (
            <WithTarget target={`/talent/edit/vitrine`}>
              <Ask2VerifyContainer>
                <Title>Ask your clients to confirm your cooperation</Title>
              </Ask2VerifyContainer>
            </WithTarget>
          );
        else return null;
      case SmartBannerTypes.VERIFY_COLLABORATORS:
        return (
          <WithTarget target={`/review`} onClick={clickTeammateBanner}>
            <Title>Review {teammateNumbers} {teammateNumbers > 1 ? 'users' : 'user'} you may have worked with!</Title>
          </WithTarget>
        );
      case SmartBannerTypes.VERIFY_CHANNEL:
        return (
          <WithTarget target={`/channel/verified-channels-description`} onClick={clickVerifyBanner}>
            <Title>Please verify your channel account</Title>
          </WithTarget>
        );
      case SmartBannerTypes.CHANGE_EMAIL:
        return (
          <WithTarget target={`/channel/settings`} onClick={clickChangeEmailBanner}>
            <Title>Please provide your email address</Title>
          </WithTarget>
        );
    }
  }
  if (variant === SmartBannerTypes.NONE) return null;

  return (
    <Container data-testid='smart-banner-complete-profile'>
      {getSmartContent()}
    </Container>
  );
};
export default SmartBanner;
const Container = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  height: max-content;
  background: linear-gradient(90deg, #b721ff 4.69%, #21d4fd 100%);
`;

const Ask2VerifyContainer = styled.section`
  display: flex;
  align-items: center;
  > svg.new-verified-icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
  @media ${device.mobileL} {
    > svg.new-verified-icon {
      display: none;
    }
  }
`;

