import { useCallback, useEffect, useState } from "react";
import { THEME_EVENT_NAME, THEME_STORAGE_KEY, ThemeStates, ThemeEventType } from "../../api/interfaces";



export const useTheme = () => {

  const [ theme, setTheme ] =
    useState<string>(localStorage.getItem(THEME_STORAGE_KEY) || ThemeStates.Light);

  const storageChangeEventHandler = useCallback((event: StorageEvent) => {
    if ((event.key === THEME_STORAGE_KEY)) {
      setTheme((s) => (event.newValue !== s) ? (event.newValue || '') : s);
    }
  }, [setTheme]);

  const themeChangeEventHandler = useCallback((event: CustomEventInit<ThemeEventType>) => {
    const theme = event.detail?.appTheme;
    if (theme) {
      setTheme(theme);
    }
  }, [setTheme]);

  const toggleTheme = useCallback(() => {
    if (theme === ThemeStates.Dark) {
      localStorage.setItem(THEME_STORAGE_KEY, ThemeStates.Light);
      window.dispatchEvent(
        new CustomEvent<ThemeEventType>(
          THEME_EVENT_NAME,
          {
            detail: { appTheme: ThemeStates.Light }
          }
        )
      );
    }
    if (theme === ThemeStates.Light) {
      localStorage.setItem(THEME_STORAGE_KEY, ThemeStates.Dark);
      window.dispatchEvent(
        new CustomEvent<ThemeEventType>(
          THEME_EVENT_NAME,
          {
            detail: { appTheme: ThemeStates.Dark }
          }
        )
      );
    }
  }, [theme]);

  useEffect(() => {
    window.addEventListener('storage', storageChangeEventHandler);
    return () => {
      window.removeEventListener('storage', storageChangeEventHandler);
    }
  }, [storageChangeEventHandler]);

  useEffect(() => {
    window.addEventListener(THEME_EVENT_NAME, themeChangeEventHandler);
    return () => {
      window.removeEventListener(THEME_EVENT_NAME, themeChangeEventHandler);
    }
  }, [themeChangeEventHandler]);


  return {
    theme,
    toggleTheme
  };
}