import { PageContentContainer } from '../../Layout';
import useUser from '../../../hooks/useUser';
import {
  AllReadMessageIcon,
  ThumbnailContainer,
  EndSection,
  LogoutContainer,
  LogoutTitle,
  PagesWrapper,
  PopupContainer,
  StyledLogo,
  StyledUserAvatar,
  UnreadMessageIcon,
} from './StyledComponents';
import SecondaryItem from './SecondaryItem';
import { getAvatarUrl, getChatUrl } from '../../../Utils';
import { useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useOutsideAlerter } from '../../../hooks/useOutsideClicked';
import TalentPopupThumbnail from '../TalentPopupThumbnail';
import { LocalStorageKeys } from '../../../Interfaces';
import { Link } from 'react-router-dom';
import { BsGear } from 'react-icons/bs';
import styled from 'styled-components';
import LogoPlaceholder from '../../../Assests/Images/logoPlaceholder';
import { ToggleTheme } from '../../../Elements/ToggleTheme';

interface Props {
  onlyLogo: boolean;
}
const TalentContent: React.FC<Props> = ({ onlyLogo }) => {
  const { talentInfo, talentToken } = useUser();
  const name = talentInfo?.name;
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const { popupRef } = useOutsideAlerter(() => setIsPopupVisible(false));

  return (
    <PageContentContainer
      style={{ height: '100%' }}
      data-testid='menu-talent-content'
    >
      <PagesWrapper>
        <StyledLogo />
        {!onlyLogo && (
          <>
            <SecondaryItem destination='/' isActive={location.pathname === '/'} label='Home' />
            <SecondaryItem destination='/job/search/all_categories' label='Jobs' />
            <SecondaryItem destination='/talent/search/all_categories?page=1' label='Talent' />
            <SecondaryItem destination='/feed' label='Feed' />
            <SecondaryItem destination='/talent/share-space' label='ShareSpace' />
            <EndSection>
              <ToggleTheme />
              <Link to={'/talent/settings'}><SettingIcon/></Link>
              <LinkWithHover to={'/talent/notifications'}>
                {!!talentInfo?.unreadNotificationCount ? <BellActive /> : <BellDeactive />}
              </LinkWithHover>
              <a
                href={getChatUrl(talentToken)}
                target='_blank'
                rel='noreferrer'
              >
                {talentInfo?.unreadMessageCount ? (
                  <UnreadMessageIcon />
                ) : (
                  <AllReadMessageIcon />
                )}
              </a>
              <StyledUserAvatar
                alt={name}
                onClick={() => {
                  if (!isPopupVisible) {
                    setIsPopupVisible(true);
                  }
                }}
                logoUrl={getAvatarUrl(talentInfo?.avatar)}
              />
              {isPopupVisible && (
                <PopupContainer ref={popupRef}>
                  <ThumbnailContainer>
                    <TalentPopupThumbnail
                      onClick={() => setIsPopupVisible(false)}
                      avatar={
                        typeof talentInfo?.avatar === 'string'
                          ? talentInfo?.avatar
                          : LogoPlaceholder
                      }
                      talentId={talentInfo?.id}
                      isClickable={true}
                      functionalArea={talentInfo?.functionalArea}
                      channelName={talentInfo?.name || null}
                    />
                  </ThumbnailContainer>
                  <LogoutContainer
                    onClick={() => {
                      localStorage.removeItem(LocalStorageKeys.TalentToken);
                      document.location.reload();
                    }}
                  >
                    <LogoutIcon />
                    <LogoutTitle>Logout</LogoutTitle>
                  </LogoutContainer>
                </PopupContainer>
              )}
            </EndSection>
          </>
        )}
      </PagesWrapper>
    </PageContentContainer>
  );
};
export default TalentContent;

export const SettingIcon = styled(BsGear)`
  color: var(--color-gray3);
  width: 20px;
  height: 20px;
  stroke-width: 0.25px;
  margin: 2px 8px 0 0;
  &:hover {
    color: var(--color-primary-dark);
  }
`;
export const LogoutIcon = styled(FiLogOut)`
  color: var(--color-gray2);
`;
export const LinkWithHover = styled(Link)`
  &:hover {
    path, circle {
      fill: var(--color-primary-dark);
    }
  }
`
export const BellActive = () => (
  <svg className='bell-notification' style={{cursor: 'pointer', margin: '0px 8px 0 0', width: 21, height: 22}} width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2164_34576)">
    <path d="M22.5545 17.6619L20.6545 10.8259C20.0976 8.82327 18.8872 7.06422 17.2158 5.8284C15.5444 4.59259 13.5079 3.95092 11.43 4.0054C9.35201 4.05988 7.3519 4.80738 5.74756 6.12908C4.14321 7.45078 3.0267 9.27084 2.57549 11.2999L1.10449 17.9149C0.942009 18.6459 0.945766 19.404 1.11549 20.1333C1.28521 20.8625 1.61656 21.5444 2.08508 22.1285C2.55359 22.7126 3.14731 23.184 3.8224 23.5079C4.4975 23.8318 5.23672 24 5.98549 23.9999H7.09949C7.32901 25.1302 7.94222 26.1464 8.83524 26.8763C9.72825 27.6062 10.8461 28.0049 11.9995 28.0049C13.1528 28.0049 14.2707 27.6062 15.1638 26.8763C16.0568 26.1464 16.67 25.1302 16.8995 23.9999H17.7375C18.5083 24 19.2687 23.8218 19.9592 23.4794C20.6498 23.1369 21.2518 22.6394 21.7183 22.0258C22.1848 21.4122 22.5031 20.699 22.6483 19.942C22.7935 19.185 22.7618 18.4046 22.5555 17.6619H22.5545ZM11.9995 25.9999C11.3812 25.9974 10.7789 25.8039 10.2748 25.4458C9.77073 25.0878 9.38958 24.5828 9.18349 23.9999H14.8155C14.6094 24.5828 14.2283 25.0878 13.7242 25.4458C13.2201 25.8039 12.6178 25.9974 11.9995 25.9999ZM20.1255 20.8149C19.8468 21.1846 19.4858 21.4842 19.0711 21.6899C18.6564 21.8956 18.1994 22.0018 17.7365 21.9999H5.98549C5.53628 21.9999 5.09281 21.8989 4.68783 21.7045C4.28286 21.5101 3.9267 21.2273 3.64566 20.8768C3.36461 20.5264 3.16585 20.1173 3.06406 19.6798C2.96226 19.2423 2.96001 18.7874 3.05749 18.3489L4.52749 11.7329C4.88185 10.1391 5.7588 8.70954 7.01895 7.67138C8.2791 6.63323 9.85012 6.04611 11.4823 6.00335C13.1144 5.96059 14.714 6.46465 16.0268 7.4354C17.3396 8.40616 18.2902 9.78788 18.7275 11.3609L20.6275 18.1969C20.753 18.6423 20.773 19.1108 20.6859 19.5653C20.5988 20.0197 20.4069 20.4476 20.1255 20.8149Z" fill="#F54E50"/>
    </g>
    <circle cx="18" cy="7" r="6" fill="#F54E50" stroke="white" strokeWidth="2"/>
    <defs>
    <clipPath id="clip0_2164_34576">
    <rect width="24" height="24" fill="white" transform="translate(0 4)"/>
    </clipPath>
    </defs>
  </svg>
)
export const BellDeactive = () => (
  <svg className='bell-notification' style={{cursor: 'pointer', margin: '4px 8px 0 0', width: 20, height: 20}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2164_34224)">
    <path d="M22.5545 13.6619L20.6545 6.82593C20.0976 4.82327 18.8872 3.06422 17.2158 1.8284C15.5444 0.592587 13.5079 -0.049079 11.43 0.00540109C9.35201 0.0598812 7.3519 0.807381 5.74756 2.12908C4.14321 3.45078 3.0267 5.27084 2.57549 7.29993L1.10449 13.9149C0.942009 14.6459 0.945766 15.404 1.11549 16.1333C1.28521 16.8625 1.61656 17.5444 2.08508 18.1285C2.55359 18.7126 3.14731 19.184 3.8224 19.5079C4.4975 19.8318 5.23672 20 5.98549 19.9999H7.09949C7.32901 21.1302 7.94222 22.1464 8.83524 22.8763C9.72825 23.6062 10.8461 24.0049 11.9995 24.0049C13.1528 24.0049 14.2707 23.6062 15.1638 22.8763C16.0568 22.1464 16.67 21.1302 16.8995 19.9999H17.7375C18.5083 20 19.2687 19.8218 19.9592 19.4794C20.6498 19.1369 21.2518 18.6394 21.7183 18.0258C22.1848 17.4122 22.5031 16.699 22.6483 15.942C22.7935 15.185 22.7618 14.4046 22.5555 13.6619H22.5545ZM11.9995 21.9999C11.3812 21.9974 10.7789 21.8039 10.2748 21.4458C9.77073 21.0878 9.38958 20.5828 9.18349 19.9999H14.8155C14.6094 20.5828 14.2283 21.0878 13.7242 21.4458C13.2201 21.8039 12.6178 21.9974 11.9995 21.9999ZM20.1255 16.8149C19.8468 17.1846 19.4858 17.4842 19.0711 17.6899C18.6564 17.8956 18.1994 18.0018 17.7365 17.9999H5.98549C5.53628 17.9999 5.09281 17.8989 4.68783 17.7045C4.28286 17.5101 3.9267 17.2273 3.64566 16.8768C3.36461 16.5264 3.16585 16.1173 3.06406 15.6798C2.96226 15.2423 2.96001 14.7874 3.05749 14.3489L4.52749 7.73293C4.88185 6.13914 5.7588 4.70954 7.01895 3.67138C8.2791 2.63323 9.85012 2.04611 11.4823 2.00335C13.1144 1.96059 14.714 2.46465 16.0268 3.4354C17.3396 4.40616 18.2902 5.78788 18.7275 7.36093L20.6275 14.1969C20.753 14.6423 20.773 15.1108 20.6859 15.5653C20.5988 16.0197 20.4069 16.4476 20.1255 16.8149Z" fill="var(--color-gray3)"/>
    </g>
    <defs>
    <clipPath id="clip0_2164_34224">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>
)

