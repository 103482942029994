import React, { HTMLAttributes, ReactNode, useEffect } from 'react';
import styled from 'styled-components/macro';
import { deviceMin } from '../../consts/device';
import { ScrollStyle } from '../../Styles';
import { modalAnimation } from '../../Styles/Animation';

interface Props extends HTMLAttributes<HTMLDivElement> {
  modalTitle?: ReactNode;
  modalSubtitle?: ReactNode;
  setIsCloseModal?: () => void;
  contentBody?: ReactNode;
  topIcon?: ReactNode;
  className?: string;
}
const YtModal: React.FC<Props> = ({
  modalTitle,
  modalSubtitle,
  contentBody,
  setIsCloseModal,
  topIcon,
  ...props
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <ModalContainer {...props}>
      <ModalWrapper>
        <ModalGrayBlank
          onClick={setIsCloseModal}
          data-testid='gray-popup-section'
        />
        <ModalContent id='modal_content'>
          <ModalGrayDash/>

          {contentBody && (
            <ModalContentBodyWrapper>
              <ModalContentBody id='modal_content_body'>
                {topIcon}
                {modalTitle && (
                  <ModalTitle id='modal_title'>{modalTitle}</ModalTitle>
                )}
                {modalSubtitle && (
                  <ModalSubtitle id='modal_subtitle'>
                    {modalSubtitle}
                  </ModalSubtitle>
                )}
                {contentBody}
              </ModalContentBody>
            </ModalContentBodyWrapper>
          )}
        </ModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
};
export default YtModal;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.24);
  z-index: 100;
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const ModalGrayBlank = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--color-modal-backdrop);
  // desktop
  @media ${deviceMin.tabletS} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const ModalContent = styled.div<{ position?: 'CENTER' | 'BOTTOM' }>`
  display: flex;
  flex-direction: column;
  //margin-top: auto;
  width: 100%;
  height: auto;
  max-height: 80%;
  padding: 0 1rem;
  padding-bottom: 4rem;
  background: var(--color-bg1);
  box-shadow: 0px -3px 8px var(--color-menu-shadow);
  border-radius: 16px 16px 0px 0px;
  position: absolute;
  bottom: 0;
  ${modalAnimation}
  @media ${deviceMin.tabletS} {
    padding: 1rem;
    padding-bottom: 1rem;
  }

  //
  ${({ position }) =>
    position === 'CENTER' &&
    `
        width:90%;
        bottom:unset;
        left: 5%;
        top: 20%;
        height: 60%;
        overflow: auto;
        border-radius: 1rem;
      `}

  // desktop
  @media ${deviceMin.tabletS} {
    //margin: auto;
    padding: 2rem 3rem;
    border-radius: 16px;
    min-width: 455px;
    max-width: 80%;
    width: max-content;
    z-index: 110;
    position: relative;
  }
`;

const ModalGrayDash = styled.div`
  flex-shrink: 0;
  // desktop
  @media ${deviceMin.tabletS} {
    display: none;
  }
  width: 2rem;
  height: 3px;
  background: var(--color-gray4);
  border-radius: 16px;
  margin: 0.5rem auto 1.25rem auto;
`;

const ModalContentBodyWrapper = styled.div`
  ${ScrollStyle}
  overflow: auto;
`;
const ModalContentBody = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-gray2);
  position: relative;
`;
const ModalTitle = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 1rem;
  width: 100%;
  @media ${deviceMin.tabletS} {
    font-size: 22px;
    line-height: 33px;
    margin-right: auto;
    text-align: center;
  }
`;
const ModalSubtitle = styled.h5`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
  // white-space: pre-line;
  color: var(--color-gray2);
  @media ${deviceMin.tabletS} {
    font-size: 16px;
    line-height: 31px;
    margin-right: auto;
    text-align: center;
  }
`;
