const KEY2SAVE = 'df54c4e6-6d4c-4d2f-bb56-17f737c18d7a'

export interface IChannelStorageItem {
  avatar: string;
  id: string;
  name: string;
  token: string;
  active: boolean;
}
export interface IChannelAccounts {
  [channelId: string]: IChannelStorageItem;
}
function decodeBase64(base64: string): string {
  return Buffer.from(base64, 'base64').toString('utf8');
}
function encodeBase64(text: string): string {
  return Buffer.from(text, 'utf8').toString('base64');
}
export const readChannelAccounts = (): IChannelAccounts => {
  try {
    return JSON.parse(decodeBase64(localStorage.getItem(KEY2SAVE) ?? '')) as IChannelAccounts;
  } catch (error) {
    console.log(error);
    return {} as IChannelAccounts;
  }
}
export const upsertChannelAccount = (account: IChannelStorageItem) => {
  const acs = readChannelAccounts();
  acs[account.id] = account;
  localStorage.setItem(
    KEY2SAVE,
    encodeBase64(JSON.stringify(acs))
  )
}
export const removeChannelAccount = (id: string): IChannelStorageItem | undefined => {
  const acnts = readChannelAccounts();
  if (id in acnts) {
    delete acnts[id];
    localStorage.setItem(
      KEY2SAVE,
      encodeBase64(JSON.stringify(acnts))
    )
  }
  for (const key in acnts) {
    if (Object.prototype.hasOwnProperty.call(acnts, key)) {
      const item = acnts[key];
      return item;
    }
  }
}

export const switchTo = (channel: IChannelStorageItem) => {
  const acnts = readChannelAccounts();
  Object.getOwnPropertyNames(acnts).forEach(key => {
    acnts[key].active = false;
  });
  acnts[channel.id] = {...channel, active: true};
  localStorage.setItem(
    KEY2SAVE,
    encodeBase64(JSON.stringify(acnts))
  )
}


