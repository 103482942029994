import styled from 'styled-components/macro';
import { device, deviceMin } from '../../consts/device';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const Title = styled.p`
  font-family: Poppins;
  color: white;
  margin-right: 1rem;
  height: fit-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  white-space: pre;
  @media ${device.mobileL} {
    width: min-content;
  }
  //////
  span {
    margin-right: 0.5rem;
    width: max-content;
    @media ${device.mobileL} {
      width: max-content;
      white-space: pre-line;
      display: block;
    }
  }
  @media ${deviceMin.mobileM} {
    font-size: 14px;
  }
  @media ${deviceMin.tabletS} {
    margin-right: 2.25rem;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: white;
    display: contents;
  }
  .bold {
    font-weight: bold;
  }
`;
export { Title, TitleContainer };
