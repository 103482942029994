import React from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import { CookiePolicyHelper } from '../Utils/cookie';
import ReactDOM from 'react-dom';
import { device } from '../consts/device';
import { Button } from '../Elements/Button';


export function CookiePolicy() {
  const [isSubmitted, setSubmit] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setSubmit(CookiePolicyHelper.isSubmittedByUser());
    }, 15000);
  }, []);

  if (isSubmitted) return null;
  return ReactDOM.createPortal(
    <>
      {/*<BackDrop />*/}
      <Container>
        <p>We use necessary cookies to make our site work.
          We also set performance and functionality cookies that help us make improvements by measuring traffic on our site.
          For more detailed information, please see our <a href={'/cookie-policy'} target={'_blank'} rel="noreferrer">Cookie Policy</a>.</p>
        <GotIt onClick={() => {
          setSubmit(true);
          CookiePolicyHelper.submittedByUser();
        }}>Got it!</GotIt>
      </Container>
    </>,
    document.getElementById('cookiePolicy') || document.body
  );
}
const fadeInKeyFrame = keyframes`
  to {
    transform: translateX(-50%) translateY(0);
  }
`;
const fadeInKeyFrameMobile = keyframes`
  to {
    transform: translateX(0) translateY(0);
  }
`;
const Container = styled.div`
  position: fixed;
  z-index: 9999;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%) translateY(400px);
  max-width: var(--max-width-page);
  min-width: var(--max-width-page);
  border-radius: 16px;
  background-color: var(--color-gray6);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray1);
  box-shadow: 0 0 3px 2px #00000022, 0 0 7px 4px #00000019, 0 0 9px 10px #00000017;
  > p {
    font-size: 14px;
    line-height: 31px;
    > a {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  ${css`
    animation: ${fadeInKeyFrame} 0.4s 1 forwards;
  `}
  @media ${device.tabletS} {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0) translateY(400px);
    height: auto;
    min-width: 0;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 16px 16px 0 0;
    > p {
      width: 100%
    }
    ${css`
      animation: ${fadeInKeyFrameMobile} 0.4s 1 forwards;
    `}
  }
`;
const GotIt = styled(Button)`
  margin-left: 20px;
  background-color: var(--color-bg2) !important;
  color: var(--color-primary) !important;
  border-radius: 16px;
  border: 1px solid var(--color-primary) !important;
`;

// const BackDrop = styled.div`
//   position: fixed;
//   inset: 0;
//   z-index: 9998;
//   background-color: rgba(0, 0, 0, 0.12);
// `;