import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Layout, { PageContentContainer } from '../../Components/Layout';
import Menu from '../../Components/Menu';
import Seo from '../../Components/Seo';
import {
  Container,
  StyledMdErrorOutline,
  Title,
  Subtitle,
  ApplyButton,
} from './StyledComponents';

const OnlyTalentPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Seo />
      <Menu />
      <PageContentContainer style={{ margin: 'auto' }}>
        <Container>
          <StyledMdErrorOutline />
          <Title>Only Talent</Title>
          <Subtitle>
            {`Only talent are allowed to visit this page. If you are a talent, please login`}
          </Subtitle>
          <ApplyButton onClick={() => navigate(`/login`)}>Login</ApplyButton>
        </Container>
      </PageContentContainer>
      <Footer />
    </Layout>
  );
};
export default OnlyTalentPage;
