import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Body16Style } from '../../../Elements/Typo';
import { MenuItem, PrimaryItem, Divider } from './StyledComponent';

interface Props {
  closeHandler: () => void;
}

const NotLoginContent: React.FC<Props> = ({ closeHandler }) => {
  return (
    <>
      <MenuItem
        to={"/login"}
        onClick={() => {
          closeHandler();
        }}
      >
        Post a job
      </MenuItem>
      <Divider />
      <MenuItem
        to={"/"}
        onClick={() => {
          closeHandler();
        }}
      >
        Home
      </MenuItem>
      <MenuItem
        to={"/job/search/all_categories"}
        onClick={() => {
          closeHandler();
        }}
      >
        Jobs
      </MenuItem>
      <MenuItem
        to={'/talent/search/all_categories?page=1'}
        onClick={() => {
          closeHandler();
        }}
      >
        Talent
      </MenuItem>
      <MenuItem
        to={"/feed"}
        onClick={() => {
          closeHandler();
        }}
      >
        Feed
      </MenuItem>
      <MenuItem
        to={"/talent/signup"}
        onClick={() => {
          closeHandler();
        }}
      >
        Join as Talent
      </MenuItem>

      <Divider style={{marginBottom: 0}}/>

      <SignIn
        to={"/login?type=t"}
        onClick={() => {
          closeHandler();
        }}
      >
        Login
      </SignIn>
      <PrimaryItem
        style={{marginTop: '1rem'}}
        to={"/signup"}
        onClick={() => {
          closeHandler();
        }}
      >
        Sign up
      </PrimaryItem>
    </>
  );
};
export default NotLoginContent;

const SignIn = styled(Link)`
  ${Body16Style};
  color: var(--color-gray1);
  line-height: 20px;
  font-weight: 600;
  margin-top: 1rem;
  width: 100%;
  text-align: center;
`;
