import { deviceMin } from '../../consts/device';
import { Button } from '../../Elements/Button';
import { Body16, H3Style } from '../../Elements/Typo';
import { MdErrorOutline } from 'react-icons/md';
import styled from 'styled-components/macro';
import { fadeinAnimation } from '../../Styles/Animation';

export const Container = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${fadeinAnimation}
`;
export const StyledMdErrorOutline = styled(MdErrorOutline)`
  color: var(--color-error);
  width: 3.5rem;
  height: 3.5rem;
`;
export const Title = styled.h1`
  ${H3Style}
  margin-top: 1rem;
  font-weight: bold;
  line-height: 30px;
`;
export const Subtitle = styled(Body16)`
  font-weight: normal;
  line-height: 22px;
  margin-top: 1rem;
  text-align: center;
`;
export const ApplyButton = styled(Button)`
  width: 100%;
  margin-top: 3rem;
  //desktop
  @media ${deviceMin.tabletS} {
    width: auto;
  }
`;
