import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Layout, { PageContentContainer } from '../../Components/Layout';
import Menu from '../../Components/Menu';
import Seo from '../../Components/Seo';

import {
  Container,
  StyledMdErrorOutline,
  Title,
  Subtitle,
  ApplyButton,
} from './StyledComponents';

const OnlyChannelPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Seo />
      <Menu />
      <PageContentContainer style={{ margin: 'auto' }}>
        <Container>
          <StyledMdErrorOutline />
          <Title>Access Denied!</Title>
          <Subtitle>
            {`Only channels have access to this page. If you have a channel account on YT Jobs, please log in.`}
          </Subtitle>
          <ApplyButton onClick={() => navigate(`/login`)}>Login</ApplyButton>
        </Container>
      </PageContentContainer>
      <Footer />
    </Layout>
  );
};
export default OnlyChannelPage;
