
const REFFERAL_KEY = 'nkjSfd8dSvfS9';

interface IItem {
  refferer: string;
  date: number;
}

// 1 month represented by ms
const VALID_FOR = 30 * 24 * 60 * 60 * 1000;

const getItem = () => {
  const itemString = localStorage.getItem(REFFERAL_KEY) ||
    JSON.stringify({
      refferer: '',
      date: 1,
    } as IItem);
  return JSON.parse(itemString) as IItem;
};
const setItem = (refferer: string) => {
  localStorage.setItem(REFFERAL_KEY, JSON.stringify(
    {
      refferer,
      date: Date.now(),
    }
  ))
};

export class RefferalHandler {
  static SetRefferer(refferer: string) {
    setItem(refferer);
  }
  static GetRefferer(): string | undefined {
    const item = getItem();
    if ((Date.now() - item.date) < VALID_FOR) {
      // setTimeout(() => {
      //   localStorage.removeItem(REFFERAL_KEY);
      // }, 500)
      return item.refferer;
    }
    return undefined;
  }
}