import { useEffect, useRef } from 'react';

export function useOutsideAlerter(outsideClickHandler: () => void) {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        outsideClickHandler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [outsideClickHandler, popupRef]);
  return { popupRef };
}
