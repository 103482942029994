import { Helmet } from "react-helmet";
import CommonImage from "./images/heroPhoto.png";
// import { toTitleCase } from "./Utils";
// This is the thumbnail that appears when someone shares your website

import { siteMetadata } from "./consts";
import { JobStructuredData } from "../../Interfaces/SeoStructurdData";
import { IFAQStructure } from "../../Pages/FAQ/utils";

interface Props {
  title?: string;
  description?: string;
  lang?: string;
  type?: string;
  meta?: ConcatArray<
    { name: string; content: string } | { property: string; content: string }
  >;
  keywords?: string;
  image?: string;
  structuredData?: JobStructuredData | IFAQStructure;
  link?: Array<{rel: string; href: string}>
}

function Seo({
  description: descriptionProps,
  lang = `en`,
  type,
  meta = [],
  link = [],
  title: titleProps,
  keywords: keywordsProps,
  image,
  structuredData,
}: Props) {
  const {
    siteUrl,
    defaultTitle,
    keywords,
    defaultDescription,
    legalName,
    email,
  } = siteMetadata;

  const structuredDataOrganization: Record<string, unknown> = {
    "@context": "http://schema.org",
    "@type": type,
    legalName: legalName,
    url: siteUrl,
    // logo: logo,
    // foundingDate: '${foundingDate}',
    // founders: [
    //   {
    //     '@type': 'Person',
    //     name: '${legalName}',
    //   },
    // ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        email: email,
        // telephone: '${contact.phone}',
        contactType: "customer service",
      },
    ],
    // address: {
    //   '@type': 'PostalAddress',
    //   addressLocality: '${address.city}',
    //   addressRegion: '${address.region}',
    //   addressCountry: '${address.country}',
    //   postalCode: '${address.zipCode}',
    // },
    sameAs: [
      "https://twitter.com/intent/follow?screen_name=yt_jobs",
      // '${socialLinks.google}',
      // '${socialLinks.youtube}',
      // '${socialLinks.linkedin}',
      // '${socialLinks.instagram}',
      // '${socialLinks.github}',
    ],
  };
  const smartTitle = typeof titleProps === 'string' ? titleProps : defaultTitle;
  const smartDescription = typeof descriptionProps === 'string' ? descriptionProps : defaultDescription;
  const smartKeywords = typeof keywordsProps === 'string' ? keywordsProps : keywords;
  const smartImage = `${siteUrl}${image || CommonImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[...link]}
      title={/*toTitleCase(smartTitle)*/smartTitle.replace("Yt Jobs", "YT Jobs")}
      meta={[
        {
          name: `description`,
          content: smartDescription,
        },
        {
          property: `og:title`,
          content: smartTitle,
        },
        {
          property: `og:image`,
          content: smartImage,
        },
        {
          property: `og:description`,
          content: smartDescription,
        },
        {
          property: `og:keywords`,
          content: smartKeywords,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].filter(item => Boolean(item.content)).concat(meta)}
    >
      <script type="application/ld+json">
        {structuredData
          ? JSON.stringify(structuredData)
          : JSON.stringify(structuredDataOrganization)}
      </script>
    </Helmet>
  );
}

export default Seo;
