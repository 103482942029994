import "./Styles/style.scss";

import { QueryClient, QueryClientProvider } from "react-query";
import ToasterContainer from "./Components/ToasterContainer";
import { PublicModalContextProvider } from "./Contexts/PublicModalContenxt";
import Routes from "./Routes";
import { ReactQueryDevtools } from "react-query/devtools";
import ErrorBoundary from "./ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
import { CookiePolicy } from './Components/cookie-policy';
import { ChannelHJSurvey } from "./Components/HJNPSSurvey";
import { ReferringSourceHandler } from "./Components/RefferrerSourceHandler";
import { ThemeProvider } from "./Components/ThemeProvider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60000 * 5, //5min
    },
  },
});

export default function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <PublicModalContextProvider>
          <ToasterContainer />
          <ThemeProvider>
            <BrowserRouter>
              <ReferringSourceHandler />
              <Routes />
              <ChannelHJSurvey />
            </BrowserRouter>
          </ThemeProvider>
          <CookiePolicy />
        </PublicModalContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
