import { FiLogOut } from 'react-icons/fi';
import useUser from '../../../hooks/useUser';
import { LocalStorageKeys } from '../../../Interfaces';

import {
  LogoutContainer,
  LogoutTitle,
  MenuItem,
  StyledTalentPopupThumbnail,
  ThumbnailContainer,
} from './StyledComponent';

interface Props {
  closeHandler: () => void;
}

const TalentContent: React.FC<Props> = ({ closeHandler }) => {
  const { talentInfo } = useUser();
  return (
    <>
      <ThumbnailContainer>
        <StyledTalentPopupThumbnail
          avatar={
            typeof talentInfo?.avatar === 'string' ? talentInfo?.avatar : ''
          }
          talentId={talentInfo?.id}
          isClickable={true}
          functionalArea={talentInfo?.functionalArea}
          channelName={talentInfo?.name || null}
        />
      </ThumbnailContainer>
      <MenuItem
        to={'/'}
        onClick={() => {
          closeHandler();
        }}
      >
        Home
      </MenuItem>
      <MenuItem
        to={'/job/search/all_categories'}
        onClick={() => {
          closeHandler();
        }}
      >
        Jobs
      </MenuItem>
      <MenuItem
        to={'/talent/search/all_categories?page=1'}
        onClick={() => {
          closeHandler();
        }}
      >
        Talent
      </MenuItem>
      <MenuItem
        to={'/feed'}
        onClick={() => {
          closeHandler();
        }}
      >
        Feed
      </MenuItem>
      <MenuItem
        to={'/talent/share-space'}
        onClick={() => {
          closeHandler();
        }}
      >
        ShareSpace
      </MenuItem>
      {location.pathname.startsWith(`/talent/profile/${talentInfo?.id}`) && (
        <MenuItem
          to={'/talent/settings'}
          onClick={() => {
            closeHandler();
          }}
        >
          Settings
        </MenuItem>
      )}
      <LogoutContainer
        onClick={() => {
          localStorage.removeItem(LocalStorageKeys.TalentToken);
          document.location.reload();
        }}
      >
        <FiLogOut />
        <LogoutTitle>Logout</LogoutTitle>
      </LogoutContainer>
    </>
  );
};
export default TalentContent;
