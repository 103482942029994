import { ChannelInfo, ChannelProfile } from '../../../Interfaces/Channel';
import {
  JobDetailForOwner,
  JobListForOwner,
  JobProducts_PaymentHistory,
} from '../../../Interfaces/Job';
import { TReciveEmailStatus, TalentApplicantInfo, YtVideo } from '../../../Interfaces/Talent';
import { PaginateQueryResponse } from '../index';

export interface ExpressChannelLoginResponse {
  companyToken: string;
  company: ChannelInfo;
}
export type CurrentChannelResponse = ChannelProfile;

export enum ApplicationPriority {
  undecided = 'undecided',
  lowPriority = 'lowPriority',
  highPriority = 'highPriority',
  accepted = 'accepted',
  hired = 'hired',
}
export interface ApplicationData {
  applicationId: string;
  status: ApplicationPriority;
  talent: TalentApplicantInfo;
  youtubeVideo?: YtVideo;
  location?: string;
  coverLetter?: string;
  id: number;
}
export interface GetJobDetailByOwnerResponse {
  success: boolean;
  applicants: ApplicationData[];
  job: JobDetailForOwner;
  paymentRequired: boolean;
  payment: JobProducts_PaymentHistory;
}
export type GetJobApplicantsByOwnerResponse = PaginateQueryResponse<ApplicationData>

export type GetJobListByOwnerResponse = JobListForOwner[];
///////////create job success//////////////
export interface CreateChannelSuccessRes {
  success: boolean;
  channel: ChannelInfo;
}
export interface CreateCompanySuccessRes {
  success: boolean;
  company: ChannelInfo;
  companyToken: string;
  message: string;
}
export interface CheckChannelSuccessRes {
  hasRegistered: boolean;
  isVideo: boolean;
  channel: ChannelInfo;
}
export interface IChannelSettings {
  notifications: {
    emailMessages: TReciveEmailStatus;
  }
}
export interface ICoupon {
  code: string;
  valid: boolean;
  redeemBy: string;
  name: string;
  amountOff: number | null; // in cents
  percentOff: number | null;
  redeemed: boolean;
}
export interface IReferralInfo {
  referral: {
    referringCode: string;
    referralsCount: 0;
  };
  coupons: ICoupon[];
}
//
export enum UserChannelQueryLabels {
  getChannelDetail = 'getChannelDetail',
  getJobDetailByOwner = 'getJobDetailByOwner',
  getJobApplicantsByOwner = 'getJobApplicantsByOwner',
  getJobApplicantDetailByOwner = 'getJobApplicantDetailByOwner',
  getCurrentChannel = 'getCurrentChannel',
  getJobsByOwner = 'getJobsByOwner',
  getSettings = 'getSettings',
  referralInfo = 'referralInfo',
}
