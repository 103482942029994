
import { AxiosError } from 'axios';
import { QueryKey, useQuery, useQueryClient } from 'react-query';
import { QueryFilters } from 'react-query/types/core/utils';
import { getCurrentChannel } from '../api/fetchers/User/Channel';
import { getCurrentTalent } from '../api/fetchers/User/Talent';
import { RoutinErrorResponse } from '../api/interfaces';
import {
  UserChannelQueryLabels,
  CurrentChannelResponse,
} from '../api/interfaces/User/Channel';
import {
  GetTalentDetailNotSuspended,
  TalentQueryLabels,
} from '../api/interfaces/Talent';

import { SharedProfileData, LocalStorageKeys, UserRoles } from '../Interfaces';
import { ChannelProfile } from '../Interfaces/Channel';
import { TalentInfo } from '../Interfaces/Talent';
import { UserTalentQueryLabels } from '../api/interfaces/User/Talent';
import { useLocation, useNavigate } from 'react-router-dom';
import { VideoType } from '../Components/Talent/Vitrine/ShowAll/ShortLongVideoSwitch';
import React from 'react';
import { isACompany } from '../Utils/channel';

export default function useUser(): {
  role: UserRoles;
  //
  isErrorChannel: boolean;
  isLoadingChannel: boolean;
  channelInfo: ChannelProfile | undefined;
  channelToken: string | null;
  //
  talentInfo: TalentInfo | undefined;
  isErrorTalent: boolean;
  isLoadingTalent: boolean;
  talentToken: string | null;
  smartToken: string | null;
  refetchTalentInfo: (queryKey?: QueryKey, filters?: QueryFilters) => void;
  removeTalentInfo: (queryKey?: QueryKey, filters?: QueryFilters) => void;
  refetchChannelInfo: () => void;
  //
  sharedProfileData: SharedProfileData | null;
} {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const filter: VideoType = React.useMemo(() => {
    const f = (new URLSearchParams(location.search)).get('filter');
    return (f ? f : 'all') as VideoType;
  }, [location.search]);
  const channelToken = localStorage.getItem(LocalStorageKeys.ChannelToken);
  const talentToken = localStorage.getItem(LocalStorageKeys.TalentToken);
  //////////////////////////////////////////////
  /////////Queries//////////////////////////////
  /////////////////////////////////////////////
  const {
    isError: isErrorChannel,
    isLoading: isLoadingChannel,
    data: channelInfo,
    refetch: refetchChannelInfo
  } = useQuery<CurrentChannelResponse, AxiosError<RoutinErrorResponse>>(
    [UserChannelQueryLabels.getCurrentChannel, channelToken],
    getCurrentChannel,
    {
      enabled: !!channelToken,
      onSuccess: (res) => {
        if (isACompany(res) && res.name === null) {
          navigate('/company/profile-wizard');
        }
      }
    }
  );
  //
  const {
    // refetch: refetchCurrentTalen,
    data: talentInfo,
    isError: isErrorTalent,
    isLoading: isLoadingTalent,
  } = useQuery<GetTalentDetailNotSuspended, AxiosError<RoutinErrorResponse>>(
    [UserTalentQueryLabels.getCurrentTalent],
    getCurrentTalent,
    {
      enabled: !!talentToken,
    }
  );
  ///////////////////////////////
  ///////////////////////////////
  function getSmartRole() {
    if (channelToken) return UserRoles.CHANNEL;
    if (talentToken) return UserRoles.TALENT;
    return UserRoles.NOT_LOGIN;
  }
  ///////////////////////////////
  function removeTalentInfo() {
    queryClient.removeQueries([UserTalentQueryLabels.getCurrentTalent]);
    queryClient.removeQueries([
      TalentQueryLabels.getTalentDetail,
      talentInfo?.id,
    ]);
    queryClient.removeQueries([
      TalentQueryLabels.getInfiniteTalentVideos,
      { id: talentInfo?.id },
    ]);
  }

  ///////////////////////////////
  function refetchTalentInfo() {
    queryClient.refetchQueries({
      queryKey: [UserTalentQueryLabels.getCurrentTalent],
    });
    if (filter) {
      queryClient.refetchQueries({
        queryKey: [
          TalentQueryLabels.getTalentDetail,
          { id: talentInfo?.id + filter }
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          TalentQueryLabels.getInfiniteTalentVideos,
          { id: talentInfo?.id + filter },
        ],
      });
    } else {
      queryClient.refetchQueries({
        queryKey: [TalentQueryLabels.getTalentDetail, talentInfo?.id],
      });
      queryClient.refetchQueries({
        queryKey: [
          TalentQueryLabels.getInfiniteTalentVideos,
          { id: talentInfo?.id },
        ],
      });
    }
  }

  ////////
  function getSharedProfileData(): SharedProfileData {
    if (getSmartRole() === UserRoles.CHANNEL)
      return {
        name: channelInfo?.name,
        id: channelInfo?.id,
        email: channelInfo?.email,
        avatar: channelInfo?.avatar,
        unreadMessageCount: channelInfo?.unreadMessageCount,
      };
    if (getSmartRole() === UserRoles.TALENT)
      return {
        name: talentInfo?.name,
        id: talentInfo?.id,
        email: talentInfo?.email,
        avatar: talentInfo?.avatar,
        unreadMessageCount: talentInfo?.unreadMessageCount,
      };
    return {};
  }
  return {
    role: getSmartRole(),
    //
    isErrorChannel,
    isLoadingChannel,
    channelInfo: channelInfo,
    channelToken,
    //
    talentInfo: talentInfo,
    isErrorTalent: isErrorTalent,
    talentToken,
    refetchTalentInfo,
    removeTalentInfo,
    refetchChannelInfo,
    //
    sharedProfileData: getSharedProfileData(),
    isLoadingTalent,
    //
    smartToken: channelToken || talentToken,
  };
}
