import React, { HTMLAttributes } from 'react';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';
import useDevice from '../../hooks/useDevice';
interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const SmartFooter: React.FC<Props> = ({ className, ...props }) => {
  const { isLaptop } = useDevice();
  if (isLaptop) return <DesktopFooter {...props} className={className} />;
  return <MobileFooter {...props} className={className} />;
};
export default SmartFooter;
