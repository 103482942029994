import { useContext } from 'react';
import { PublicModalContext } from '../../Contexts/PublicModalContenxt';
import { PublicPopupVariant } from '../../Interfaces';
import LoginPopup from './Modals/LoginPopup';

const PublicModals = () => {
  const { activeModalOpen } = useContext(PublicModalContext);

  switch (activeModalOpen) {
    case PublicPopupVariant.LOGIN_CHANNEL:
      return <LoginPopup role={'CHANNEL'} />;
    case PublicPopupVariant.LOGIN_TALENT:
      return <LoginPopup role={'TALENT'} />;
    case PublicPopupVariant.NONE:
      return null;
    default:
      return null;
  }
};
export default PublicModals;
