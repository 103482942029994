import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button } from '../../Elements/Button';
import Icon from '../../Elements/YtIcons';
import { Body16, H1 } from '../../Elements/Typo';
import { YtjobsIcons } from '../../Interfaces';

interface ErrorProps {
  title?: string;
  subtitle?: string;
  destination?: string;
  ctlLabel?: string;
}
export default function Content({
  title = 'Something went wrong. code 422',
  subtitle = 'Please try again',
  destination = '/',
  ctlLabel = 'Home',
}: ErrorProps) {
  const navigate = useNavigate();
  return (
    <Container>
      <StyledIcon variant={YtjobsIcons.error} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {destination && (
        <ApplyButton onClick={() => navigate(`${destination}`)}>
          {ctlLabel}
        </ApplyButton>
      )}
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledIcon = styled(Icon)`
  color: var(--color-error);
  width: 3.5rem;
  height: 3.5rem;
`;
const Title = styled(H1)`
  margin-top: 1rem;
  font-weight: bold;
  line-height: 30px;
`;
const Subtitle = styled(Body16)`
  font-weight: normal;
  line-height: 22px;
  margin-top: 1rem;
`;
const ApplyButton = styled(Button)`
  width: 100%;
  margin-top: 3rem;
`;
