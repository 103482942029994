import { ChannelInfo } from "../Interfaces/Channel";

export const isACompany = (info?: ChannelInfo) => {
  return !!info?.isGeneral;
}
export const isNeededChangeEmail = (info?: ChannelInfo) => {
  if (!info) return false;
  const { email } = info;
  if (!email) return true;
  const emailParts = email.split('@');
  return (
    emailParts.length === 2 && emailParts[1].includes('plusgoogle')
  );
}