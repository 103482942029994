import { MouseEventHandler } from 'react';

/**
 * Primary UI component for user interaction
 */
interface Props {
  width?: number;
  height?: number;
  className?: string;
  fill?: string;
  onClick?: MouseEventHandler<SVGElement>;
}
const LoadingIcon = ({
  onClick,
  width = 24,
  height = 30,
  className,
  fill = 'var(--color-primary)',
}: Props) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 24 30`}
      //   style="enable-background:new 0 0 50 50;"
      xmlSpace='preserve'
    >
      <rect x='0' y='13' width='4' height='5' fill={fill}>
        <animate
          attributeName='height'
          attributeType='XML'
          values='5;21;5'
          begin='0s'
          dur='0.6s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='y'
          attributeType='XML'
          values='13; 5; 13'
          begin='0s'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </rect>
      <rect x='10' y='13' width='4' height='5' fill={fill}>
        <animate
          attributeName='height'
          attributeType='XML'
          values='5;21;5'
          begin='0.15s'
          dur='0.6s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='y'
          attributeType='XML'
          values='13; 5; 13'
          begin='0.15s'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </rect>
      <rect x='20' y='13' width='4' height='5' fill={fill}>
        <animate
          attributeName='height'
          attributeType='XML'
          values='5;21;5'
          begin='0.3s'
          dur='0.6s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='y'
          attributeType='XML'
          values='13; 5; 13'
          begin='0.3s'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </rect>
    </svg>
  );
};
export default LoadingIcon;
