export function getCookie(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export function setCookie(cname: string, cValue: string, exDays: number = 365) {
  const d = new Date();
  d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
  const expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cValue + ";" + expires + ";path=/";
}

export class CookiePolicyHelper {
  private static COOKIE_POLICY_NAME = 'COOKIE_POLICY_SUBMIT';
  private static COOKIE_POLICY_VALUE = 'SUBMITTED';
  public static isSubmittedByUser() {
    return getCookie(this.COOKIE_POLICY_NAME) === this.COOKIE_POLICY_VALUE;
  }
  public static submittedByUser() {
    setCookie(this.COOKIE_POLICY_NAME, this.COOKIE_POLICY_VALUE);
  }
}