import { SmartBannerTypes } from '../interface';
import { ProfileStatus, TalentInfo } from '../../../Interfaces/Talent';
import { ChannelProfile } from '../../../Interfaces/Channel';
import { useChannelData } from './useChannelData';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { isACompany, isNeededChangeEmail } from '../../../Utils/channel';

interface Props {
  talentInfo: TalentInfo | undefined;
  channelInfo: ChannelProfile | undefined;
}

const useSmartBanner = ({ talentInfo, channelInfo }: Props)
  : {smartBannerVariant: SmartBannerTypes; hasTeammate: boolean; userNumbers: number;  } => {
  const pathName = window.location.pathname;
  const { id: channelId } = useParams(); // if in the /channel/:id
  const { userNumbers, teammateExist, hasChannelActiveJob } = useChannelData(Boolean(channelInfo?.id));
  const verificationBannerNeeded = useMemo(() => {
    if (talentInfo?.id) {
      const allClients = Array.from(new Set(talentInfo?.youtubeVideos.videos.map(v => v.channelThumbnail)));
      if (allClients.length) {
        const clientsHaveVerified = Array.from(new Set(talentInfo.youtubeVideos.videos.filter(v => v.confirmationStatus === 'verified').map(v => v.channelThumbnail)));
        if (allClients.length === 1) {
          // * Show when the user has one client and has not verified yet.
          return clientsHaveVerified.length < 1;
        } else {
          // * Show when the user has multiple clients and has been verified by just one or non of them.
          return clientsHaveVerified.length <= 1;
        }
      } else {
        // * Do Not show when the user has not any client
        return false;
      } 
    } else {
      // * Do Not show when the user is not a talent
      return false;
    }
  }, [talentInfo]);





  const bannerState = () => {
    if (
      talentInfo?.id &&
      talentInfo?.profileStatus === ProfileStatus.INCOMPLETE &&
      pathName !== '/talent/profile-wizard' &&
      pathName !== '/talent/signup' &&
      pathName !== `/talent/${talentInfo?.id}`
    )
      return SmartBannerTypes.COMPLETE_PROFILE;
    if (
      verificationBannerNeeded &&
      !pathName.startsWith('/talent/edit/vitrine') &&
      !pathName.startsWith('/talent/profile-wizard') &&
      !pathName.startsWith('/talent/signup')
    )
      return SmartBannerTypes.ASK_FOR_REVIEW;
    if (
      channelInfo &&
      channelInfo.id &&
      !isACompany(channelInfo) &&
      !channelInfo.isVerified &&
      (hasChannelActiveJob ? true : parseInt(channelInfo.subscribers || '0') >= 10000) &&
      !pathName.includes('verified-channels-description') &&
      !pathName.includes('/review') &&
      !pathName.startsWith('/job/create') &&
      !pathName.includes('/checkout') &&
      !pathName.includes('/only-verified-channel')
    )
      return SmartBannerTypes.VERIFY_CHANNEL
    if (
      channelInfo &&
      channelInfo.id &&
      isNeededChangeEmail(channelInfo) &&
      !pathName.startsWith('/channel/settings') &&
      !pathName.startsWith('/job/create') &&
      !pathName.includes('/checkout') &&
      !pathName.includes('/review')
    )
      return SmartBannerTypes.CHANGE_EMAIL
    if (
      channelInfo?.id &&
      channelInfo?.isVerified &&
      !isACompany(channelInfo) &&
      Boolean(userNumbers) &&
      !pathName.startsWith('/teammates') &&
      !pathName.startsWith('/review') &&
      !pathName.startsWith('/job/create') &&
      !pathName.includes('/checkout') &&
      !pathName.endsWith('/applicants')
    )
      return SmartBannerTypes.VERIFY_COLLABORATORS

    return SmartBannerTypes.NONE;
  }
  return {
    smartBannerVariant: bannerState(),
    hasTeammate: teammateExist && Boolean(channelInfo?.isVerified) && (channelId === channelInfo?.id),
    userNumbers
  }
};
export default useSmartBanner;
