import React, { useContext } from "react";
import Modal from "../../../Modal";
import styled from "styled-components/macro";
import { device, deviceMin } from "../../../../consts/device";
import { PublicModalContext } from "../../../../Contexts/PublicModalContenxt";
import { PublicPopupVariant } from "../../../../Interfaces";
import { Button } from "../../../../Elements/Button";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Logo";
import {
  Body14Style,
  Body16Style,
} from "../../../../Elements/Typo";
import { YtLink } from "../../../../Elements/Link";
import { RedirectHandler } from "../../../../Utils/redirect-handler";

interface Props {
  role: "TALENT" | "CHANNEL";
  close?: () => void;
}
const LoginPopup: React.FC<Props> = ({close}) => {
  const { setActiveModalOpen } = useContext(PublicModalContext);
  const navigate = useNavigate();
  return (
    <StyledModal
      topIcon={<StyledLogo variant="PURE" />}
      modalTitle={"Membership required!"}
      modalSubtitle={"To see more, join YT Jobs first."}
      setIsCloseModal={() => close ? close() : setActiveModalOpen(PublicPopupVariant.NONE)}
      contentBody={
        <CTAsContainer>
          <CTA
            onClick={() => {
              RedirectHandler.setRedirect(location.pathname);
              navigate("/login");
              setActiveModalOpen(PublicPopupVariant.NONE);
            }}
          >
            Login
          </CTA>
          <Desc2>Not a member?</Desc2>
          <Row>
            <StyledLink
              to="/talent/signup"
              onClick={() => {
                RedirectHandler.setRedirect(location.pathname);
                setActiveModalOpen(PublicPopupVariant.NONE)
              }}
              type="PALE"
            >
              {`I want a job`}
            </StyledLink>
            <VerticalDivider />
            <StyledLink
              to="/channel/signup"
              onClick={() => {
                RedirectHandler.setRedirect(location.pathname);
                setActiveModalOpen(PublicPopupVariant.NONE)
              }}
              type="PALE"
            >
              I want to hire
            </StyledLink>
          </Row>
        </CTAsContainer>
      }
    />
  );
};
export default LoginPopup;

const StyledModal = styled(Modal)`
  #modal_content {
  }
  #modal_content_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.1rem;
    width: 100%;
  }
  #modal_title {
    @media ${device.mobileL} {
      margin-bottom: 0.5rem;
    }
  }
`;
const StyledLogo = styled(Logo)`
  margin-top: 1rem;
  width: 2.5rem;
  @media ${device.mobileL} {
    margin-top: 0.75rem;
  }
`;
const CTAsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const CTA = styled(Button)`
  width: 100%;
  margin: 0.5rem 0 2rem;
  height: 3rem;
  @media ${deviceMin.tabletS} {
    width: auto;
  }
`;
const Desc2 = styled.h3`
  ${Body14Style};
  font-weight: 600;
  line-height: 20px;
  color: var(--color-gray1);
  margin-bottom: 0.5rem;
  @media ${deviceMin.tabletS} {
    ${Body16Style}
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const VerticalDivider = styled.div`
  width: 1px;
  height: 1rem;
  border-left: 1px solid var(--color-gray4);
  margin: 0 0.5rem;
`;
const StyledLink = styled(YtLink)`
  ${Body14Style}
  color: var(--color-primary);
  line-height: 28px;
  font-weight: 600;
  height: 2rem;
  @media ${deviceMin.tabletS} {
    ${Body16Style}
    color: var(--color-primary);
    line-height: 30px;
  }
`;
