import axios from 'axios';
import ErrorToast from '../../Elements/Toast/Error';
import { LocalStorageKeys } from '../../Interfaces';
import { getSmartBaseUrl } from '../utils';

/////////////////////////////////
const baseURL = getSmartBaseUrl();

const ytJobsApi = axios.create({
  baseURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
});

ytJobsApi.interceptors.request.use(
  (config) => {
    const channelToken = localStorage.getItem(LocalStorageKeys.ChannelToken);
    const userToken = localStorage.getItem(LocalStorageKeys.TalentToken);
    const smartToken = channelToken || userToken || undefined;
    if (smartToken) {
      config.headers.Authorization = `Bearer ${smartToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ytJobsApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem(LocalStorageKeys.TalentToken);
      localStorage.removeItem(LocalStorageKeys.ChannelToken);
      ErrorToast('You logged out! Please log in again.');
      return (window.location.href = '/login');
    }
    return Promise.reject(error);
  }
);

export { ytJobsApi };
