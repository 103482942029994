import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Body14Style, Body16Style } from '../../../Elements/Typo';
import ChannelThumbnail from '../../Channel/ChannelThumbnail';
import UserAvatar from '../../UserAvatar';

import TalentPopupThumbnail from '../TalentPopupThumbnail';
export const StyledUserAvatar = styled(UserAvatar)`
  margin: 1rem auto;
  filter: contrast(90%);
`;
export const MenuItem = styled(Link)<{active?: boolean;}>`
  ${Body16Style};
  width: 100%;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
  ${({active}) => active && `color: var(--color-primary);`}
`;
export const PrimaryItem = styled(Link)`
  ${Body16Style};
  color: var(--color-primary);
  cursor: pointer;
  padding-bottom: 1.5rem;
  width: 100%;
  text-align: center;
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--color-gray5);
  margin-bottom: 1.5rem;
`
export const ThumbnailContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--color-gray5);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;
export const StyledChannelThumbnail = styled(ChannelThumbnail)`
  justify-content: center;
`;

export const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--color-gray4);
  padding: 0.8rem 2.6rem 0.8rem 1.5rem;
  cursor: pointer;
  > svg {
    color: var(--color-gray1);
  }
`;
export const LogoutTitle = styled.h3`
  ${Body14Style};
  color: var(--color-gray1);
  margin-left: 0.5rem;
`;

export const StyledTalentPopupThumbnail = styled(TalentPopupThumbnail)`
  justify-content: center;
`;
