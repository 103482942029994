import { ExpertiseID, GoogleAuthModes, IGoogleAuthBody, IGoogleAuthRes } from "../../Interfaces";
import { RefferalHandler } from "../../Utils/refferalHandler";
import { ytJobsApi } from "../clients";
import { GetChannelDetailResponse } from "../interfaces/Channel";

//if we want to get all channels we don't have to add filter
export const getChannelsList = async ({
  limit,
  filter,
}: {
  limit: number;
  filter?: {
    isVerified: boolean;
    hasJob:boolean;
  };
  
}) => {
  const { data } = await ytJobsApi.get(`/companies`, {
    params: {
      limit,
      filter,
    },
  });

  return data;
};
export const getChannelDetail = async (
  id: string | undefined
): Promise<GetChannelDetailResponse> => {
  if (!id) throw new Error("Id not found");
  const { data } = await ytJobsApi.get(`/companies/${id}`);
  return data;
};
export const getCompanyCustomConfig = async (): Promise<{showNPS: boolean}> => {
  const { data } = await ytJobsApi.get(`/custom-configs`);
  return data;
};
export const GoogleAuthUtil = {
  url: `auth/oauth/auth-link`,
  modes: GoogleAuthModes,
  // ***** Channel ***** \\
  companyRegister: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.CompanyRegis,
      referringSource: RefferalHandler.GetRefferer(),
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  companyLogin: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.CompanyLogin,
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  companyVerifyYT: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.CompanyVerifyYT,
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },

  // **** Talent **** \\
  userVerifyYT: async function (channelUrl: string) {
    const body: IGoogleAuthBody = {
      mode: this.modes.UserVerifyYT,
      channelUrl
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  userRegister: async function (functionalArea: ExpertiseID) {
    const body: IGoogleAuthBody = {
      mode: this.modes.UserRegis,
      functionalArea,
      referringSource: RefferalHandler.GetRefferer(),
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  userLogin: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.UserLogin,
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
  userVerifyEmail: async function () {
    const body: IGoogleAuthBody = {
      mode: this.modes.UserVerifyEmail,
    };
    const { data } = await ytJobsApi.post<IGoogleAuthRes>(this.url, body);
    return data;
  },
}

