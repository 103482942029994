import styled, { css } from 'styled-components/macro';
import { Body16Style } from '../Typo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
const InputContainer = styled.div<{
  border?: 'bottom' | 'full';
  disabled: boolean;
}>`
  display: flex;
  padding-bottom: 8px;
  align-items: center;
  width: 100%;
  ${({ border }) =>
    border === 'bottom'
      ? `border-bottom: 2px solid var(--color-gray4);`
      : `box-shadow: 0px 3px 8px rgba(8, 8, 43, 0.06);border: 1px solid var(--color-gray4);border-radius: 12px;padding-bottom: 0px; height: 3.5rem;padding: 0 1rem;
    background-color: var(--color-bg1);`}

  //////////////focus-whithin///////////////////////
  :focus-within {
    ${({ border }) =>
      border === 'full' &&
      'border: 1px solid var(--color-gray2);box-shadow: 0px 3px 8px rgba(8, 8, 43, 0.06);'}
  }
  ////////////disabled////////////
  ${({ disabled }) => disabled && 'border-color:var(--color-gray5); '};
`;
const InputStyle = css<{
  hasError: boolean;
  disabled: boolean;
}>`
  ${Body16Style}
  line-height: 22px;
  font-weight: 600;

  ${({ hasError }) => hasError && 'color:var(--color-error);'};
  ${({ disabled }) => disabled && 'color:var(--color-gray3); '};
  width: inherit;
  height: inherit;

  ::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-gray4);
    ${({ disabled }) => disabled && 'color:var(--color-gray5); '};
  }
`;
const StyledInput = styled.input`
  ${InputStyle}
`;

const Error = styled.h2`
  color: var(--color-error);
  margin-top: 0.5rem;
`;
export { Error, StyledInput, InputContainer, Container, InputStyle };
