import React, { HTMLAttributes } from 'react';

import styled from 'styled-components/macro';
import useUser from '../../hooks/useUser';
import { UserRoles } from '../../Interfaces';
import {
  StyledLogo,
  Row,
  MailboxIcon,
  CopyRight,
  TwitterIcon,
  Hr,
  RightSide,
  SectionContainer,
  StyledLink,
  Title,
} from './StyledComponents';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}
const Footer: React.FC<FooterProps> = ({ className, ...props }) => {
  const { role, channelInfo } = useUser();
  return (
    <Container {...props} className={className}>
      <Wrapper>
        <SectionContainer>
          <Title>Home</Title>
          <StyledLink to='/job/search/all_categories'>Jobs</StyledLink>
          <StyledLink to='/talent/search/all_categories?page=1'>Talent</StyledLink>
          <StyledLink to='/faq'>FAQ</StyledLink>
          {
            role === UserRoles.CHANNEL && !!channelInfo?.isVerified && (
              <StyledLink to='/channel/pricing'>Pricing</StyledLink>
            )
          }
        </SectionContainer>
        <SectionContainer>
          <Title>Login</Title>
          <StyledLink
            to={role === UserRoles.CHANNEL ? '/job/create' : '/login'}
          >
            Post a Job
          </StyledLink>
          <StyledLink to='/talent/signup'>Join as Talent</StyledLink>
        </SectionContainer>
        <SectionContainer>
          <Title>Jobs</Title>
          <StyledLink to='/job/search/video_editor'>
            Video Editor <Span>Jobs</Span>
          </StyledLink>
          <StyledLink to='/job/search/creative_director'>
            Creative Director <Span>Jobs</Span>
          </StyledLink>
          <StyledLink to='/job/search/thumbnail_designer'>
            Thumbnail Designer <Span>Jobs</Span>
          </StyledLink>
          <StyledLink to='/job/search/channel_manager'>
            Channel Manager <Span>Jobs</Span>
          </StyledLink>
          <StyledLink to='/job/search/youtube_strategist'>
            Youtube Strategist <Span>Jobs</Span>
          </StyledLink>
          <StyledLink to='/job/search/scriptwriter'>
            Scriptwriter <Span>Jobs</Span>
          </StyledLink>
          <StyledLink to='/job/search/youtube_producer'>
            Youtube Producer <Span>Jobs</Span>
          </StyledLink>
        </SectionContainer>
        <SectionContainer>
          <Title>Talent</Title>
          <StyledLink to='/talent/search/video_editor'>Video Editor</StyledLink>
          <StyledLink to='/talent/search/creative_director'>
            Creative Director
          </StyledLink>
          <StyledLink to='/talent/search/thumbnail_designer'>
            Thumbnail Designer
          </StyledLink>
          <StyledLink to='/talent/search/channel_manager'>
            Channel Manager
          </StyledLink>
          <StyledLink to='/talent/search/youtube_strategist'>
            Youtube Strategist
          </StyledLink>
          <StyledLink to='/talent/search/scriptwriter'>Scriptwriter</StyledLink>
          <StyledLink to='/talent/search/youtube_producer'>
            Youtube Producer
          </StyledLink>
        </SectionContainer>
        <Hr />
        <RightSide>
          <StyledLogo variant='LIGHT' />
          <Row
            href='mailto:support@ytjobs.co'
            style={{ marginBottom: '0.7rem' }}
          >
            <MailboxIcon />
            <p>support@ytjobs.co</p>
          </Row>
          <Row href='https://twitter.com/intent/follow?screen_name=yt_jobs'>
            <TwitterIcon />
            <p>@yt_jobs</p>
          </Row>
          <Row href='/privacy-policy'>
            <p style={{marginTop: '0.5rem'}}>Privacy Policy</p>
          </Row>
          <Row href='/terms-and-conditions'>
            <p style={{marginTop: '0.5rem'}}>Terms & Conditions</p>
          </Row>
          <CopyRight>Copyright © {(new Date().getFullYear())} ytjobs.co, All Rights Reserved</CopyRight>
        </RightSide>
      </Wrapper>
    </Container>
  );
};
export default Footer;

const Container = styled.section`
  width: 100%;
  height: 15rem;
  background: #1e1e1f;
  position: relative;
  margin-top: auto;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: var(--max-width-page);
  height: 100%;
  margin: auto;
  padding: 0 1rem;
`;

const Span = styled.span`
  color: var(--color-gray3);
`;
