import { ytJobsApi } from '../clients';
import {
  SendEmailsInvitationResponse,
  SendErrorLogReq,
  SendErrorSucsessResponse,
  SendLoginLinkResponse,
} from '../interfaces';
import { IReferralInfo } from '../interfaces/User/Channel';
import { ExpressTalentLoginResponse } from '../interfaces/User/Talent';

export const expressUserLogin = async (
  token: string
): Promise<ExpressTalentLoginResponse> => {
  const { data } = await ytJobsApi.post<ExpressTalentLoginResponse>(
    `/auth/user/express`,
    {
      token,
    }
  );
  return data;
};

export const sendLoginLink = async (
  email: string
): Promise<SendLoginLinkResponse> => {
  const { data } = await ytJobsApi.post<SendLoginLinkResponse>(
    `/auth/login-link`,
    {
      email,
    }
  );
  return data;
};
export const sendEmailsInvitation = async (
  emails: string[]
): Promise<SendEmailsInvitationResponse> => {
  const { data } = await ytJobsApi.post<SendEmailsInvitationResponse>(
    `/referral/invite`,
    {
      emails,
    }
  );
  return data;
};
//////////
export const getReferralInfo = async (): Promise<IReferralInfo> => {
  const { data } = await ytJobsApi.get<IReferralInfo>(
    `/referral/info`
  );
  return data;
};
////////
export const sendErrorLog = async (
  params: SendErrorLogReq
): Promise<SendErrorSucsessResponse> => {
  ////////////
  const { data } = await ytJobsApi.post<SendErrorSucsessResponse>(
    `/utility/error-log`,
    {
      ...params,
    }
  );
  return data;
};
