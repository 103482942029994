import React, { useState } from 'react';
import { PublicPopupVariant } from '../Interfaces';

type Context = {
  activeModalOpen: PublicPopupVariant;
  setActiveModalOpen: React.Dispatch<React.SetStateAction<PublicPopupVariant>>;
};
type Props = {
  children: React.ReactNode;
};

const PublicModalContext = React.createContext({} as Context);
function PublicModalContextProvider({ children }: Props) {
  const [activeModalOpen, setActiveModalOpen] = useState<PublicPopupVariant>(
    PublicPopupVariant.NONE
  );
  ///////////////////
  return (
    <PublicModalContext.Provider
      value={{
        activeModalOpen,
        setActiveModalOpen,
      }}
    >
      {children}
    </PublicModalContext.Provider>
  );
}
export { PublicModalContextProvider, PublicModalContext };
