
const REDIRECT_ITEM_KEY = 'vnksjdFFdssdfh';

interface IRedirectItem {
  path: string;
  date: number;
}

// 10min represented by ms
const VALID_FOR = 10 * 60 * 1000;

const getRedirectItem = () => {
  const itemString = localStorage.getItem(REDIRECT_ITEM_KEY) ||
    JSON.stringify({
      path: '',
      date: 1,
    } as IRedirectItem);
  return JSON.parse(itemString) as IRedirectItem;
};
const setRedirectItem = (path: string) => {
  localStorage.setItem(REDIRECT_ITEM_KEY, JSON.stringify(
    {
      path,
      date: Date.now(),
    }
  ))
};

export class RedirectHandler {
  static setRedirect(path: string) {
    setRedirectItem(path);
  }
  static getRedirect(): string | undefined {
    const item = getRedirectItem();
    if ((Date.now() - item.date) < VALID_FOR) {
      setTimeout(() => {
        localStorage.removeItem(REDIRECT_ITEM_KEY);
      }, 500)
      return item.path;
    }
    return undefined;
  }
}