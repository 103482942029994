export interface RoutinErrorResponse {
  message: string;
}
export interface SendLoginLinkResponse {
  message: string;
}
export interface SendEmailsInvitationResponse {
  success: boolean;
}
export interface GetReferralInfoResponse {
  referringCode: string;
  referralsCount: number;
}
export enum SendErrorLogCode {
  ErrorBoundary = 'L1',
  Image404 = 'L2',
  AxiosErrorHandler = 'AxiosErrorHandler',
  ServerError = 'ServerError',
  useLocalStorage = 'useLocalStorage',
  LazyWithRetry = 'LazyWithRetry',
}
export interface SendErrorLogReq {
  message?: string;
  trace?: string;
  email?: string;
  path?: string;
  code?: SendErrorLogCode | string;
}
export interface SendErrorSucsessResponse {
  status: boolean;
}

export interface PaginateQueryResponse<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: string[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

// Theming
export const THEME_STORAGE_KEY = 'theme';
export enum ThemeStates {
  Dark = 'dark',
  Light = 'light',
}
export const THEME_EVENT_NAME = 'UPDATE_THEME_EVENT';
export interface ThemeEventType {
  appTheme: ThemeStates;
}