import { useState, useEffect, useMemo } from 'react';
import {
  getJobsByOwner,
  getVerificationVideos,
} from "../../../api/fetchers/User/Channel";
import { ClaimedToWork } from "../../../Interfaces/Channel";
import { useQuery } from 'react-query';
import { GetJobListByOwnerResponse, UserChannelQueryLabels } from '../../../api/interfaces/User/Channel';
import { JobStatus } from '../../../Interfaces/Job';

export const useChannelData = (isChannel: boolean)
  : { teammateExist: boolean; userNumbers: number; hasChannelActiveJob: boolean; } => {
  const [teammateList, setTeammateList] = useState<ClaimedToWork[]>([]);
  const {
    data: channelJobs,
  } = useQuery<GetJobListByOwnerResponse, Error>(
    [UserChannelQueryLabels.getJobsByOwner],
    () => getJobsByOwner(),
    {
      staleTime: 600000,
      enabled: isChannel,
    }
  );
  useEffect(() => {
    if (isChannel) {
      getVerificationVideos().then((res: ClaimedToWork[]) => {
        if (res &&Array.isArray(res)) setTeammateList(res);
      });
    }
  }, [isChannel]);
  const hasChannelActiveJob = useMemo(
      () => !!channelJobs?.some(job => job.status === JobStatus.ACTIVE),
      [channelJobs]
  );
  return {
    // collaboratorsBannerNeeded: teammateList.some(item => item.videos.some(v => v.status === 'undecided')),
    teammateExist: Boolean(teammateList.length),
    userNumbers: teammateList.filter(user => user.videos.every(v => v.status === 'undecided')).length,
    hasChannelActiveJob
  };
};
