import { SharedProfileData, ExpertiseID } from ".";
import { TLanguage } from "./Job";

export enum ProfileStatus {
  INCOMPLETE = "incomplete",
  COMPLETE = "complete",
}
export type TalentWork = {
  id?: string;
  link?: string;
  description: string;
  title: string;
  startedAt: string | null;
  endedAt: string | null;
  isCurrentlyWorkingHere?: boolean;
};
export interface TalentPortfolio {
  behanceLink: string | null;
  twitterLink: string | null;
  websiteLink: string | null;
  ytLink: string | null;
}
export type VideoConfirmationStatus = 'undecided' | 'denied' | 'verified';
export type YtVideo = {
  abvComments: string;
  abvLikes: string;
  abvViews: string;
  channelId: string;
  description?: string;
  channelThumbnail: string;
  confirmationStatus: VideoConfirmationStatus;
  channelTitle: string;
  abvChannelSubscribers: string | null;
  channelSubscribers: number;
  comments: number;
  // description: string;
  id: string;
  likes: number;
  publishedAt: string;
  thumbnail: string;
  title: string;
  views: number;
  url: string;
};
export interface YtChannel {
  avatar: string;
  id: string;
  name: string;
  abvSubscribers: string;
}
export interface YoutubeVideosCollection {
  statistics: {
    abvComments: string;
    abvLikes: string;
    abvViews: string;
    counts: number;
    views?: number;
  };
  channels: YtChannel[];
  videos: YtVideo[];
}
export interface ITopic {
  name: string;
  url: string;
}
export interface IReviews {
  body: string;
  rate: number;
  createdAt: Date;
  reviewable: TalentCardInfo;
  reviewer: {
    id: string;
    avatar: string;
    abvSubscribers: string;
    abvVideoCount: string;
    abvViews: string;
    name: string;
    ytLink: string;
    isVerified: boolean;
  }
}
export interface IBadges {
  redDiamond: number;
  blueDiamond: number;
  gold: number;
  silver: number;
  bronze: number;
  simpleVerified: number;
}
export interface TalentInfo extends SharedProfileData {
  about: string | null;
  functionalArea: ExpertiseID;
  isVerified?: boolean;
  hasVerifiedVideo?: boolean;
  hasEnabledHireMe: boolean;
  discord?: string;
  portfolio: TalentPortfolio;
  reasons?: string[] | null;
  profileStatus: ProfileStatus;
  works: TalentWork[];
  customTitle: string;
  youtubeVideos: YoutubeVideosCollection;
  topics: ITopic[];
  reviews: IReviews[];
  vitrineImage?: string;
  vouchesCount: number;
  unreadNotificationCount?: number;
  pageViews?: string;
  latestVideoDate: string;
  phone?: null | string;
  linkYoutube?: null | string;
  linkTwitter?: null | string;
  emailVerified?: boolean | null; // null represents that item has no value but for false, has value but not verified.
  phoneVerified?: boolean  | null;
  isYoutubeVerified?: boolean  | null;
  isTwitterVerified?: boolean  | null;
  isLocationVerified?: boolean | null;
  canChangeFunctionalArea?: boolean;
  badges?: IBadges;
  bestBadge?: null | keyof IBadges;
  bookingAvailable?: boolean;
  isBookable?: boolean;
  isPassSet?: boolean;
  languages?: TLanguage[];
}
export interface TalentApplicantInfo extends SharedProfileData {
  videoStatistics: {
    counts: number;
    abvViews: string;
  };
  topClient: {
    abvChannelSubscribers: string;
    channelThumbnail: string;
  };
  channels: {
    abvSubscribers: string;
    avatar: string;
    id: string;
    name: string;
    hasVerifiedApplicant: boolean;
  }[];
  functionalArea: ExpertiseID;
  isVerified?: boolean;
  hasEnabledHireMe: boolean;
}
export type TalentCardInfo = Pick<
  TalentInfo,
  | "avatar"
  | "functionalArea"
  | "id"
  | "name"
  | "isVerified"
  | "hasEnabledHireMe"
  | "hasVerifiedVideo"
  | "bestBadge"
>;

export interface TalentProfile extends SharedProfileData {
  works: TalentWork[];
  about: string | null;
}
export enum VideoType {
  normal = 'Long-form',
  short  = 'Short-form',
  all    = 'No filter',
  'Long-form' = 'normal',
  'Short-form'  = 'short',
  'No filter'    = 'all',
}
export const ClientSize = {
  0: 'No filter',
  1: '1 - 10K',
  2: '10K - 100K',
  3: '100k - 1M',
  4: '+1M',
  '1 - 10K'   : '1',
  '10K - 100K': '2',
  '100k - 1M' : '3',
  '+1M'       : '4',
  'No filter'       : undefined
}
export enum Availability {
  part_time = 'part-time',
  full_time = 'full-time',
  project_based = 'project-based',
}
export enum LocationType {
  remote = 'remote',
  hybrid = 'hybrid',
  onsite = 'onsite',
}
export interface TalentListReqFilter {
  functionalArea?: ExpertiseID;
  hasEnabledHireMe?: 'true';
  verifiedOnly?: 'true';
  videoType?: 'short' | 'normal';
  clientSize?: '1' | '2' | '3' | '4';
  niches?: string[];
  languages?: string[];
  country?: {
    id: string;
    location: string;
  };
  hasFilledForm?: 'true';
  temp?: string;
  bestBadge?: keyof IBadges;
  // this is just for request
  countryId?: string;
  availability?: Availability;
  locationType?: LocationType;
}
export type Talent = {
  id: string;
  name: string;
  functionalArea: ExpertiseID;
  avatar: string;
  isVerified: boolean;
};
////////report////////
export enum TalentReportReason {
  OTHERS_VIDEO = "OthersVideo",
  FALSE_INFORMATION = "FaleInformation",
  IMPERSONATES_ME = "ImpersonateMe",
  IMPERSONATES_OTHERS = "Impersonate_Others",
  BAD_EXPEIENCE = "BadExperience",
}
///////ٰVouch///////////
export type VouchedByChannel = {
  id: string;
  name: string;
  avatar: string;
  abvSubscribers: string;
  isVerified?: boolean;
};
export interface HireMeUpdateSetting {
  hire_me_availability_full_time: boolean;
  hire_me_availability_part_time: boolean;
  hire_me_availability_project_based: boolean;
  hire_me_location_remote: boolean;
  hire_me_location_hybrid: boolean;
  hire_me_location_hybrid_city: string | null;
  cityId?: string;
  locationDetails: {
    cityId: number | null;
    countryId: number | null;
    cityName: string | null;
    countryName: string | null;
  };
}
export type TReciveEmailStatus = 'instant' | 'once_per_day' | 'dnd';
export interface ITalentSetting extends HireMeUpdateSetting {
  notifications: {
    emailMessages: TReciveEmailStatus;
  }
}
export type ClaimedToWorkTalent = Pick<
  TalentInfo,
  | "hasEnabledHireMe"
  | "isVerified"
  | "functionalArea"
  | "avatar"
  | "id"
  | "name"
>;
export interface ClaimedToWorkVideo {
  description: string;
  thumbnail: string;
  status: "verified" | "denied" | "undecided";
  id: string;
  title: string;
  abvLikes: string;
  abvViews: string;
  url: string;
  channelThumbnail:string;
  channelTitle: string;
  publishedAt: string;
}
export interface ICompany {
  id: string;
  name: string;
  avatar: string;
  abvSubscribers: string;
  description: string;
  ytLink: string;
  isVerified: boolean;
  isFeatured: number;
  jobsCount: number;
  subscribers: number;
}
