import ReactDOM from 'react-dom';
import App from './App';
import { StyleSheetManager } from 'styled-components';
// import ReactGA from 'react-ga4';
///
// if (process.env.NODE_ENV === 'production') {
//   ////ga4//////
//   ReactGA.initialize('G-8WG5KS9E2L');
// }
if (
  process.env.NODE_ENV === 'test'
  // ||
  // process.env.NODE_ENV === 'development'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}


ReactDOM.render((
  <StyleSheetManager disableCSSOMInjection={true}>
    <App />
  </StyleSheetManager>
), document.querySelector('#root'));
