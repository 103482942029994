import React, { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  avatar: string;
  abvSubscribers: string | null;
  location: 'WIZARD_PAGE' | 'USER_PROFILE_PAGE' | 'OTHER_PROFILE_PAGE';
  isClickable?: boolean;
  channelId?: string;
}
const Type3: React.FC<Props> = ({
  avatar,
  abvSubscribers,
  isClickable,
  channelId,
  ...props
}) => {
  return (
    <ChannelContainer
      to={isClickable && channelId ? `/channel/${channelId}` : ''}
      {...props}
    >
      <ChannelAvatar src={avatar} alt='channel' />
      <ChannelSubscribers>{abvSubscribers}</ChannelSubscribers>
    </ChannelContainer>
  );
};
export default Type3;
const ChannelContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
`;
const ChannelAvatar = styled.img`
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  min-height: 4rem;
  border: 2px solid white;
  border-radius: 50%;
`;
const ChannelSubscribers = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: white;
  margin-top: -1.5rem;
  background: var(--color-bg1);
  width: 100%;
  text-align: center;
  color: var(--color-gray1);
  height: 2rem;
  padding-top: 0.25rem;
  font-weight: bold;
`;
