import React from 'react';
import styled from 'styled-components/macro';
import HoverPopup from 'reactjs-popup';
import { MdVerified } from 'react-icons/md';
import { device } from '../../../consts/device';

const ChannelVerifiedIcon: React.FC<{linkOnHover?: boolean;}> = () => {
  return (
    <HoverPopup
      trigger={() => (
        <InternalLink>
          <IconWrapper>
            <StyledIcon />
          </IconWrapper>
        </InternalLink>
      )}
      position='bottom left'
      arrow={true}
      on={['hover']}
    >
      <Text>The blue checkmark in front of a channel’s name shows that the YT Jobs account is legitimately connected to the corresponding YouTube account.</Text>
    </HoverPopup>
  );
};
export default ChannelVerifiedIcon;

const InternalLink = styled.div`
  display: flex;
  align-items: center;
  //cursor: pointer;
`;
const Text = styled.p`
  font-size: 14px;
  background-color: var(--color-gray3-gray5);
  color: white;
  border-radius: 7px;
  padding: 16px;
  margin-left: -2rem;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    width: 20px;
    height: 25px;
    left: 2rem;
    clip-path: polygon(50% 0, 200% 100%, -100% 100%);
    background-color: var(--color-gray3-gray5);
  }
  max-width: 530px;
  @media ${device.tabletS} {
    max-width: 290px;
    margin-left: -8rem;
    &:before {
      height: 25px;
      left: 8rem;
    }
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  color: var(--color-accent6);
  > svg {
    max-height: 15px;
    min-height: 15px;
  }
`;
const StyledIcon = styled(MdVerified)`
  width: 100%;
  height: 100%;
`;
