import { css } from 'styled-components/macro';
import { deviceMin } from '../consts/device';

export const ScrollStyle = css`
  @media ${deviceMin.tabletS} {
    &::-webkit-scrollbar {
      width: 4px;
      height: px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray4);
      border-radius: 16px;
    }
  }
`;
