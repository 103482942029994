import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LocalStorageKeys } from "../../Interfaces";
import { RefferalHandler } from "../../Utils/refferalHandler";

const TARGET_QUERY_PARAM = 'utm_ref';

export const ReferringSourceHandler: React.FC = () => {

  const { search } = useLocation();

  useEffect(() => {
    if (search.includes(TARGET_QUERY_PARAM)) {
      const refSource = new URLSearchParams(search).get(TARGET_QUERY_PARAM);
      if (
        !(!!localStorage.getItem(LocalStorageKeys.ChannelToken) || !!localStorage.getItem(LocalStorageKeys.TalentToken))
        && refSource
      ) {
        RefferalHandler.SetRefferer(refSource);
      }

    }
  }, [search])

  return null;
}