import { PaginateQueryResponse } from ".";
import { TalentInfo, YtVideo } from "../../Interfaces/Talent";
import { Voucher } from "./User/Talent";

export interface GetTalentDetailNotSuspended extends TalentInfo {
  vouchedByCurrentUser: boolean | null;
}
export interface SuspendAccountResponse {
  isSuspended: boolean;
}
export type GetTalentDetailResponse =
  | GetTalentDetailNotSuspended
  | SuspendAccountResponse;
export type GetTalentListResponse = PaginateQueryResponse<TalentInfo>;

export type Add2DiscoveryListRes = {
  success: boolean;
  succeeded: number[];
  failed: number[];
}

export type GetTalentVideosListResponse = PaginateQueryResponse<YtVideo>;

export enum TalentQueryLabels {
  getInfinitTalentList = 'getInfinitTalentList',
  getTalentDetail = 'getTalentDetail',
  getVouchesList = 'getVouchesList',
  getInfiniteTalentVideos = 'getInfiniteTalentVideos',
}
///////ٰVouch///////////

export type GetVouchesListResponse = {
  success: boolean;
  vouches: Voucher[];
}; ////////////////

export type TalentPersonalYT = {
  abvSubscribers: string;
  abvVideoCount: string;
  abvViews: string;
  avatar: string;
  id: string;
  name: string;
}

export type TalentVerifiedInfo = {
  linkTwitter: string;
  linkYoutube: TalentPersonalYT;
  location: string;
  success: boolean;
  message: string;
}
export type TCountryCode = {
  id: string;
  name: string;
  iso2: string;
  phoneCode: string;
  emoji: string;
  query: string;
}
