import React, { Suspense } from "react";
import { HTMLAttributes } from "react";
import { AiOutlineLink } from "react-icons/ai";

import { MdModeEditOutline, MdDeleteForever } from "react-icons/md";
import { YtjobsIcons } from "../../Interfaces";
import Loading from "./Loading";

const Twitter = React.lazy(() => import(`./Twitter`));
const UserCircle = React.lazy(() => import(`./UserCircle`));
const VideoEditor = React.lazy(() => import(`./VideoEditor`));
const DiscoverHireIcon = React.lazy(() => import(`./DiscoverHireIcon`));
const CreativeDirector = React.lazy(() => import(`./CreativeDirector`));
const ErrorIcon = React.lazy(() => import(`./ErrorIcon`));
const LinkExternal = React.lazy(() => import(`./LinkExternal`));
const YouTubeStrategist = React.lazy(() => import(`./YouTubeStrategist`));
const ScriptWriter = React.lazy(() => import(`./ScriptWriter`));
const YouTubeProducer = React.lazy(() => import(`./YouTubeProducer`));
const ThumbnailDesigner = React.lazy(() => import(`./ThumbnailDesigner`));
const ChannelManager = React.lazy(() => import(`./ChannelManager`));
const MenuIcon = React.lazy(() => import(`./MenuIcon`));
const ArrowRight = React.lazy(() => import(`./ArrowRight`));
const Close = React.lazy(() => import(`./Close`));
const Salary = React.lazy(() => import(`./Salary`));
const Time = React.lazy(() => import(`./Time`));
const Location = React.lazy(() => import(`./Location`));
const SendMessage = React.lazy(() => import(`./SendMessage`));
const CheckMark = React.lazy(() => import(`./CheckMark`));
const CheckMarkFill = React.lazy(() => import(`./CheckMarkFill`));
const Reject = React.lazy(() => import(`./Reject`));
const RejectFill = React.lazy(() => import(`./RejectFill`));
const Hide = React.lazy(() => import(`./Hide`));
const Show = React.lazy(() => import(`./Show`));
const Setting = React.lazy(() => import(`./Setting`));
const Share = React.lazy(() => import(`./Share`));
const Flag = React.lazy(() => import(`./Flag`));

interface Props extends HTMLAttributes<SVGElement> {
  variant: YtjobsIcons;
}

const YtIcon: React.FC<Props> = ({ variant, ...props }) => {
  function getCurrentIcon() {
    switch (variant) {
      case YtjobsIcons.error:
        return <ErrorIcon data-testid="error-icon" {...props} />;
      case YtjobsIcons.discoverHire:
        return <DiscoverHireIcon {...props} />;
      case YtjobsIcons.videoEditor:
        return <VideoEditor {...props} />;
      case YtjobsIcons.creativeDirector:
        return <CreativeDirector {...props} />;
      case YtjobsIcons.thumbnailDesigner:
        return <ThumbnailDesigner {...props} />;
      case YtjobsIcons.channelManager:
        return <ChannelManager {...props} />;
      case YtjobsIcons.youTubeStrategist:
        return <YouTubeStrategist {...props} />;
      case YtjobsIcons.scriptWriter:
        return <ScriptWriter {...props} />;
      case YtjobsIcons.youTubeProducer:
        return <YouTubeProducer {...props} />;
      case YtjobsIcons.externalLink:
        return <LinkExternal {...props} />;
      case YtjobsIcons.userCircle:
        return <UserCircle {...props} />;
      case YtjobsIcons.menuIcon:
        return <MenuIcon {...props} />;
      case YtjobsIcons.loading:
        return <Loading {...props} />;
      case YtjobsIcons.arrowRight:
        return <ArrowRight {...props} />;
      case YtjobsIcons.close:
        return <Close {...props} />;
      case YtjobsIcons.salary:
        return <Salary {...props} />;
      case YtjobsIcons.time:
        return <Time {...props} />;
      case YtjobsIcons.location:
        return <Location {...props} />;
      case YtjobsIcons.edit:
        return <MdModeEditOutline {...props} />;
      case YtjobsIcons.link:
        return <AiOutlineLink {...props} />;
      case YtjobsIcons.delete:
        return <MdDeleteForever {...props} />;
      case YtjobsIcons.sendMessage:
        return <SendMessage {...props} />;
      case YtjobsIcons.checkMark:
        return <CheckMark {...props} />;
      case YtjobsIcons.checkMarkFill:
        return <CheckMarkFill {...props} />;
      case YtjobsIcons.reject:
        return <Reject {...props} />;
      case YtjobsIcons.rejectFill:
        return <RejectFill {...props} />;
      case YtjobsIcons.twitter:
        return <Twitter {...props} />;
      case YtjobsIcons.hide:
        return <Hide {...props} />;
      case YtjobsIcons.show:
        return <Show {...props} />;
      case YtjobsIcons.setting:
        return <Setting {...props} />;
      case YtjobsIcons.share:
        return <Share {...props} />;
      case YtjobsIcons.flag:
        return <Flag {...props} />;
    }
  }
  return <Suspense fallback={<div>.</div>}>{getCurrentIcon()}</Suspense>;
};
export default YtIcon;
