import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

interface Props {
  className?: string;
  variant?: 'DARK' | 'LIGHT' | 'PURE';
}
const Logo: React.FC<Props> = ({ className, variant = 'DARK' }) => {
  let smartSrc = '/text-logo-dark.png';
  if (variant === 'LIGHT' || (localStorage.getItem('theme') === 'dark')) smartSrc = '/text-logo-light.png';
  if (variant === 'PURE') smartSrc = '/pureLogo.png';
  return (
    <StyledLink to='/'>
      <img
        src={smartSrc}
        alt='ytjobs logo'
        className={className}
        style={{ cursor: 'pointer' }}
      />
    </StyledLink>
  );
};
export default Logo;
const StyledLink = styled(Link)`
  width: fit-content;
  display: flex;
  align-items: center;
`;
