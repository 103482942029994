import styled from "styled-components/macro";
import { Body12, Body14Style, Body18Style } from "../../Elements/Typo";
import Logo from "../Logo";
import { FaTwitter } from "react-icons/fa";
import { BsMailbox } from "react-icons/bs";
import { deviceMin } from "../../consts/device";
import { Link } from "react-router-dom";

export const TwitterIcon = styled(FaTwitter)`
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;
export const MailboxIcon = styled(BsMailbox)`
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;
export const Row = styled.a`
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
    color: white;
  }
`;
export const CopyRight = styled(Body12)`
  text-align: center;
  color: var(--color-gray3);
  margin-top: 1rem;
  font-size: 10px;
  position: absolute;
  bottom: -40%;
  left: 0%;
  width: 100%;
  white-space: pre;
  @media ${deviceMin.tabletS} {
    bottom: -20%;
  }
  @media ${deviceMin.laptopS} {
    bottom: -20%;
    left: -35%;
    width: unset;
  }
`;
export const StyledLogo = styled(Logo)`
  width: 100px;
  margin-bottom: 1rem;
  @media ${deviceMin.tabletS} {
    width: 8rem;
  }
`;
export const StyledLogoMobile = styled(Logo)`
  width: 100px;
  @media ${deviceMin.tabletS} {
    width: 8rem;
  }
`;
export const SectionContainer = styled.div`
  margin-left: 1rem;
  width: 43%;
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  @media ${deviceMin.tabletS} {
    height: 100%;
    margin: 0;
    width: auto;
  }
`;
export const Title = styled.h2`
  ${Body18Style};
  font-weight: bold;
  color: white;
  margin-bottom: 0.2rem;
`;
export const StyledLink = styled(Link)`
  ${Body14Style};
  color: white;
  margin-bottom: 0.4rem;
`;
export const Hr = styled.hr`
  background-color: var(--color-gray3);
  height: 60%;
  width: 2px;
`;
export const RightSide = styled.div`
  position: relative;
`;
