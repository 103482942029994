import { lazy } from 'react';
import { sendErrorLog } from '../api/fetchers';
import { SendErrorLogCode, SendErrorLogReq } from '../api/interfaces';
import { isItOnLive } from '../api/utils';
import { emailRegex } from '../consts';
import ErrorToast from '../Elements/Toast/Error';
import SuccessToast from '../Elements/Toast/Success';
import { ExpertiseID, LocalStorageKeys } from '../Interfaces';
import { YtVideo } from '../Interfaces/Talent';
import { convertExpertidToJobTitle } from './job';

export const convertToCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});
export const getFilterKeys = (): string[] => {
  const FilterKeys: string[] = Object.values(ExpertiseID).map((key) => {
    return convertExpertidToJobTitle(key as ExpertiseID);
  });
  return ["All Categories", ...FilterKeys];
};
export const setChannelToken = (value: string) => {
  localStorage.removeItem(LocalStorageKeys.TalentToken);
  localStorage.setItem(LocalStorageKeys.ChannelToken, value);
};
export const setTalentToken = (value: string) => {
  localStorage.removeItem(LocalStorageKeys.ChannelToken);
  localStorage.setItem(LocalStorageKeys.TalentToken, value);
};
export function getChatUrl(
  token: string | null, // token of user
  to?: string | undefined, // id of user destination
  type?: 'comp' | 'user' | undefined // type of user destination
): string {
  return `https://chat.ytjobs.co/auth/express?token=${token}${
    to ? '&to=' + to : ''
  }${type ? '&type=' + type : ''}`;
}

export const getAvatarUrl = (
  avatar: string | File | undefined | null
): string => {
  if (typeof avatar === 'string') return avatar;
  return '';
};
export const isEmailValidate = (email: string) => {
  return !!String(email).toLowerCase().match(emailRegex);
};
/////////////

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}
export const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');
  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };
  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    return dataURItoBlob(dataUrl);
  };

  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reader.onload = (readerEvent: any) => {
      image.onload = () => resolve(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const copyToClipBoard = (
  text: string,
  successMessage: string = 'Copying to clipboard was successful!'
) => {
  navigator.clipboard.writeText(text).then(
    function () {
      SuccessToast(successMessage);
    },
    function () {
      ErrorToast('Copying to clipboard was not successful!');
    }
  );
};
function mustSendError({ ...params }: SendErrorLogReq): boolean {
  if (!isItOnLive()) return false;

  if (params.trace?.includes('www.google.com/bot.html')) return false;
  if (params?.code && sessionStorage.getItem(params?.code) === 'SENT')
    return false;
  return true;
}

export const sendErrorLogToServer = ({ ...params }: SendErrorLogReq) => {
  // if error accured in live
  if (mustSendError({ ...params })) {
    try {
      sendErrorLog(params).then(() => {
        if (params?.code) sessionStorage.setItem(params.code, 'SENT');
      });
    } catch (error) {}
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithRetry = (componentImport: () => any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        LocalStorageKeys['page-has-been-force-refreshed']
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        LocalStorageKeys['page-has-been-force-refreshed'],
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          LocalStorageKeys['page-has-been-force-refreshed'],
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      sendErrorLogToServer({
        message: 'Error in lazyWithRetry',
        path: window?.location?.href,
        trace: JSON.stringify(error),
        code: SendErrorLogCode.LazyWithRetry,
      });
      throw error;
    }
  });
////////////////////
export function divideNumberAndstring(str: string): {
  numberPart: number;
  stringPart: string;
} {
  return {
    numberPart: str.match(/\d+/g) !== null ? Number(str.match(/\d+/g)?.[0]) : 0,
    stringPart: str.replace(/[0-9]/g, "").replace(".", ""),
  };
}

/////////Video//////////////
export const VideoArraySorter = (data: YtVideo[]): YtVideo[] => {
  let filteredArray: YtVideo[] = data;
  const finalArray: YtVideo[] = [];
  for (let i = 0; i < data.length; i++) {
    let mostSubscribedChannels: YtVideo = filteredArray[0];
    for (let j = 0; j < filteredArray.length; j++) {
      if (
        mostSubscribedChannels.channelSubscribers <
        filteredArray[j].channelSubscribers
      ) {
        mostSubscribedChannels = filteredArray[j];
      }
    }
    filteredArray = filteredArray.filter(
      (item) => item.channelTitle !== mostSubscribedChannels.channelTitle
    );
    finalArray.push(mostSubscribedChannels);
  }

  return finalArray;
};

//////////
export function isSafari() {
  if (
    window.navigator.userAgent.includes('Safari') &&
    !window.navigator.userAgent.includes('Mozilla')
  ) {
    return true;
  }
  return false;
}

export const hasValue = (v: unknown) => (v !== null && v !== undefined);

export const isDarkMode = () => (localStorage.getItem('theme') === 'dark');