import { ITalentSetting } from "../../../Interfaces/Talent";
import { RefferalHandler } from "../../../Utils/refferalHandler";
import { ytJobsApi } from "../../clients";
import { GetTalentDetailNotSuspended } from "../../interfaces/Talent";
import {
  TalentSignupReq,
  UpdateTalentProfileReq,
  SubmitYtVideoReq,
  PatchYtVideoReq,
  SwapPosterImageReq,
  UpdateSettingHireMeReq,
} from "../../interfaces/User/Talent";
import { isItOnLive } from "../../utils";

///////////Talent///////////////
export const talentRegister = async (request: TalentSignupReq) => {
  const body = { ...request };
  const refSource = RefferalHandler.GetRefferer();
  if (refSource) {
    body.referringSource = refSource
  }
  const { data } = await ytJobsApi.post("/auth/register", body);
  return data;
};

export const getCurrentTalent = async (): Promise<GetTalentDetailNotSuspended> => {
  const { data } = await ytJobsApi.get<GetTalentDetailNotSuspended>(`/profile`);
  return data;
};
export const changeFunctionalArea = async (functionalArea?: string): Promise<{ success: boolean; message: string }> => {
  if (!functionalArea) return { success: false, message: 'New expertise not provided!' };
  const { data } = await ytJobsApi.post<{ success: boolean; message: string }>(`/profile/change-functional-area`, {
    functionalArea
  });
  return data;
};
export const changeNotifSetting = async (policy: string) => {
  const { data } = await ytJobsApi.post<{ success: boolean; message: string }>(`/profile/settings/notification-settings`, {
    emailMessages: policy
  });
  return data;
};
export const requestResetPassword = async (email: string, userType: 'user' | 'company') => {
  const { data } = await ytJobsApi.post<{ success: boolean; message: string }>(`/auth/request-reset-password`, {
    email,
    userType
  });
  return data;
};
export const resetPasswordApi = async (email: string, password: string, token: string, userType: 'user' | 'company') => {
  const { data } = await ytJobsApi.post<{ success: boolean; message: string }>(`/auth/reset-password`, {
    email,
    password,
    password_confirmation: password,
    token,
    userType,
  });
  return data;
};
export const passwordLogin = async (email: string, password: string, userType: 'user' | 'company') => {
  const { data } = await ytJobsApi.post<{
    userToken: string;
    companyToken: string;
    user: { id: string };
    company: { id: string };
  }>(`/auth/login`, {
    email,
    password,
    userType
  });
  return data;
};

export const updateTalentProfile = async (params: UpdateTalentProfileReq) => {
  const formData = new FormData();
  formData.append("name", params?.name || "");
  formData.append("customTitle", params?.customTitle || "");
  formData.append("about", params?.about || "");
  formData.append("avatar", params?.avatar || "");
  formData.append("behanceLink", params?.portfolio?.behanceLink || "");
  formData.append("twitterLink", params?.portfolio?.twitterLink || "");
  formData.append("websiteLink", params?.portfolio?.websiteLink || "");

  formData.append("ytLink", params?.portfolio?.ytLink || "");
  formData.append("_method", "patch");
  //@ts-ignore
  if (params.works.length === 0) formData.append("works[]", []);
  else {
    for (let i = 0; i < params.works.length; i++) {
      formData.append(`works[${i}][link]`, params.works[i].link || "");
      formData.append(`works[${i}][title]`, params.works[i].title || "");
      formData.append(
        `works[${i}][isCurrentlyWorkingHere]`,
        params?.works[i]?.isCurrentlyWorkingHere ? "true" : "false"
      );
      formData.append(
        `works[${i}][startedAt]`,
        params?.works[i]?.startedAt || ""
      );
      formData.append(`works[${i}][endedAt]`, params?.works[i]?.endedAt || "");
      formData.append(
        `works[${i}][description]`,
        params.works[i].description || ""
      );
    }
  }

  // Language
  if (params.languages) {
    if (params.languages.length) {
      params.languages.forEach((ln, i) => {
        formData.append(`languages[${i}]`, ln.toString());
      })
    } else {
      formData.append("languages[]", ``)
    }
  }

  const { data } = await ytJobsApi.post(`/profile/main`, formData, {
    headers: {
      Accept: "application/json",
    },
  });

  return data;
};

////////Youtube(vitrin)////////////

export const submitYtVideo = async (props: SubmitYtVideoReq) => {
  const { data } = await ytJobsApi.post(`/youtube/video`, {
    ...props,
  });
  await PostAlarmForCaptureImageFromPoster(props.talentId);

  return data;
};

export const patchYtVideo = async (props: PatchYtVideoReq) => {
  const { videoId, description, url, talentId } = props;
  const { data } = await ytJobsApi.post(`/youtube/video/${videoId}`, {
    url,
    description,
    _method: "patch",
  });
  await PostAlarmForCaptureImageFromPoster(talentId);

  return data;
};

export const deleteYtVideo = async ({
  videoIid,
  talentId,
}: {
  videoIid: string;
  talentId: string;
}) => {
  const { data } = await ytJobsApi.delete(`/youtube/video/${videoIid}`);
  await PostAlarmForCaptureImageFromPoster(talentId);

  return data;
};

export const swapPosterImage = async ({
  originVideoId,
  targetVideoId,
  talentId,
}: SwapPosterImageReq) => {
  const { data } = await ytJobsApi.post(
    `/youtube/video/${originVideoId}/swap`,
    {
      targetVideoId,
    }
  );
  await PostAlarmForCaptureImageFromPoster(talentId);
  return data;
};
////////////////////

export const PostAlarmForCaptureImageFromPoster = async (id: string) => {
  /// only capture  image if user was existed and was on the live
  if (!id || !isItOnLive() || !!1) return null;
  const { data } = await ytJobsApi.post(
    `https://twcard.ytjobs.co/vitrine/update/${id}`
  );
  return data;
};

/////////////////Vouch//////////////
export const VouchTalent = async ({
  talentId,
  recommendationText,
}: {
  talentId: string;
  recommendationText: string;
}) => {
  if (recommendationText === "") throw new Error("Recommendation is required!");
  const { data } = await ytJobsApi.post(`/talents/${talentId}/vouches`, {
    recommendationText,
  });
  return data;
};

export const setHiddenOrShownVouch = async ({
  talentId,
  vouchId,
  isHidden,
}: {
  talentId: string;
  vouchId: string | undefined;
  isHidden: boolean;
}) => {
  if (!vouchId) throw new Error("Id not found");
  const { data } = await ytJobsApi.post(
    `/talents/${talentId}/vouches/${vouchId}/visibility`,
    { isHidden, _method: "patch" }
  );
  return data;
};
////////////////////Hire me/////////////////////////////
export const UpdateSettingHireMe = async (params: UpdateSettingHireMeReq) => {
  const { data } = await ytJobsApi.post(`/profile/settings`, params);
  return data;
};
export const getTalentSettings = async () => {
  const { data } = await ytJobsApi.get<ITalentSetting>(`/profile/settings`);
  return data;
};
export const getHireMeTalentListSettings = async (
  talentId: string | undefined
): Promise<UpdateSettingHireMeReq> => {
  if (!talentId) throw new Error("Id not found");

  const { data } = await ytJobsApi.get<UpdateSettingHireMeReq>(
    `/talents/${talentId}/settings`
  );
  return data;
};
/////////////////////////////////
