import { PageContentContainer } from '../../Layout';
import logoPlaceholder from '../../../Assests/Images/logoPlaceholder';
import useUser from '../../../hooks/useUser';
import {
  AllReadMessageIcon,
  ThumbnailContainer,
  EndSection,
  LogoutTitle,
  PagesWrapper,
  PopupContainer,
  StyledChannelThumbnail,
  StyledLogo,
  StyledUserAvatar,
  UnreadMessageIcon,
  TeammatesLink,
  ArrowRight,
  LogoutContainer2,
  LogoutContainer3,
  LogoutIcon,
  AddAccountIcon,
} from './StyledComponents';
import SecondaryItem from './SecondaryItem';
import PrimaryItem from './PrimaryItem';
import { getChatUrl } from '../../../Utils';
import { useState } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideClicked';
import { YtjobsIcons } from '../../../Interfaces';
import { isACompany } from '../../../Utils/channel';
import { Link } from 'react-router-dom';
import { BellActive, BellDeactive, LinkWithHover, SettingIcon } from './TalentContent';
import { AddChannelAccountPopop } from '../AddChannelAccountPopop';
import { useChannelAccounts } from '../Utils/useChannelAccounts';
import { ToggleTheme } from '../../../Elements/ToggleTheme';

interface Props {
  onlyLogo: boolean;
  hasTeammate: boolean;
}
const ChannelContent: React.FC<Props> = ({ onlyLogo, hasTeammate }) => {
  const { channelInfo, channelToken } = useUser();
  const [isPopUpVisible, setIsPopupVisible] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const { logout, accountsList, switchTo } = useChannelAccounts(addPopup);
  const name = channelInfo?.name;
  const { popupRef } = useOutsideAlerter(() => setIsPopupVisible(false));

  return (
    <PageContentContainer style={{ height: '100%' }}>
      <PagesWrapper>
        <StyledLogo />
        {!onlyLogo && (
          <>
            <PrimaryItem
              destination={'/job/create'}
              data-testid='menu-channel-content'
              label='Post a Job'
            />
            <SecondaryItem destination='/' isActive={location.pathname === '/'} label='Home' />
            <SecondaryItem destination='/job/search/all_categories' label='Jobs' />
            <SecondaryItem destination='/talent/search/all_categories?page=1' label='Talent' />
            <SecondaryItem destination='/feed' label='Feed' />
            <SecondaryItem destination='/channel/referral' label='Referral' />
            {!!channelInfo?.canSearchAdvanced && (
              <SecondaryItem destination='/channel/discovery' label={<>{'Discover'} <span style={{fontSize: 12}}>(beta)</span></>} />
            )}
            {/* <SecondaryItem destination='/channel/pricing' label='Pricing' /> */}
            <EndSection>
              {
                hasTeammate && <TeammatesLink to={'/review'}>Talent Review<ArrowRight variant={YtjobsIcons.arrowRight} /></TeammatesLink>
              }
              <ToggleTheme />
              <Link to={'/channel/settings'}><SettingIcon /></Link>
              <LinkWithHover to={'/channel/notifications'}>
                {channelInfo?.unreadNotificationCount ? <BellActive /> : <BellDeactive />}
              </LinkWithHover>
              <a
                href={getChatUrl(channelToken)}
                target='_blank'
                rel='noreferrer'
              >
                {channelInfo?.unreadMessageCount ? (
                  <UnreadMessageIcon />
                ) : (
                  <AllReadMessageIcon />
                )}
              </a>
              <StyledUserAvatar
                logoUrl={
                  typeof channelInfo?.avatar === 'string'
                    ? channelInfo?.avatar
                    : logoPlaceholder
                }
                alt={name}
                onClick={() => setIsPopupVisible((prevState) => !prevState)}
              />
              {isPopUpVisible && (
                <PopupContainer ref={popupRef}>
                  <ThumbnailContainer>
                    <StyledChannelThumbnail
                      variant='TYPE1'
                      avatar={channelInfo?.avatar || null}
                      isCompany={isACompany(channelInfo)}
                      abvSubscribers={channelInfo?.abvSubscribers || ''}
                      channelName={channelInfo?.name}
                      isClickable={true}
                      channelId={channelInfo?.id}
                    />
                  </ThumbnailContainer>
                  <LogoutContainer2
                    style={{ marginTop: '-16px' }}
                    onClick={logout}
                  >
                    <LogoutIcon />
                    <LogoutTitle>Logout</LogoutTitle>
                  </LogoutContainer2>

                  {!!accountsList?.length && (
                    <>
                      <LogoutContainer3>
                        <LogoutTitle style={{margin: 0}}>Other accounts:</LogoutTitle>
                      </LogoutContainer3>
                      {accountsList.map((al, i) => (
                        <LogoutContainer3
                          key={al.id}
                          hasHover={true}
                          onClick={() => {
                            switchTo(al);
                            document.location.reload();
                          }}
                          style={{paddingTop: 1, paddingBottom: i === (accountsList.length - 1) ? 1 : 8 }}
                        >
                          <img src={al.avatar} alt={al.name}/>
                          <LogoutTitle>{al.name}</LogoutTitle>
                        </LogoutContainer3>
                      ))}
                    </>
                  )}

                  <LogoutContainer2 onClick={() => setAddPopup(true)}>
                    <AddAccountIcon />
                    <LogoutTitle>Add Account</LogoutTitle>
                  </LogoutContainer2>
                </PopupContainer>
              )}

              {addPopup && (
                <AddChannelAccountPopop
                  close={() => setAddPopup(false)}
                />
              )}
            </EndSection>
          </>
        )}
      </PagesWrapper>
    </PageContentContainer>
  );
};
export default ChannelContent;


