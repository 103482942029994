import {
  slideInUp,
  fadeIn,
  slideInDown,
  slideInRight,
  slideInLeft,
  bounceIn,
  merge,
  slideOutDown,
} from 'react-animations';
import { css, keyframes } from 'styled-components/macro';

/////////BounceIn///////////
const bounceInKeyFrames = keyframes`${bounceIn}`;
const bounceInAnimation = css`
  animation: 0.4s ${bounceInKeyFrames};
`;
////////////////////
const slideUpFadeIn = merge(slideInUp, fadeIn);
const cardAnimationKeyFrames = keyframes`${slideUpFadeIn}`;
const cardAnimation = css`
  animation: 0.4s ${cardAnimationKeyFrames};
`;
//////////////fade//////////
const fadeinAnimationKeyframes = keyframes`${fadeIn}`;
const fadeinAnimation = css`
  animation: 0.8s ${fadeinAnimationKeyframes};
`;
/////////////slideUp/////////
const slideInUpAnimationKeyframes = keyframes`${slideInUp}`;
const slideInUpAnimation = css`
  animation: 0.4s ${slideInUpAnimationKeyframes};
`;
/////////////slideDown/////////
const slideOutDownAnimationKeyframes = keyframes`${slideOutDown}`;
const slideOutDownAnimation = css`
  animation: 0.4s ${slideOutDownAnimationKeyframes};
`;
/////////////slideDown/////////
const slideInDownAnimationKeyframes = keyframes`${slideInDown}`;
const slideInDownAnimation = css`
  animation: 0.4s ${slideInDownAnimationKeyframes};
`;
/////////////slideRight/////////
export const slideInRightAnimationKeyframes = keyframes`${slideInRight}`;
const slideInRightAnimation = css`
  animation: 0.4s ${slideInRightAnimationKeyframes};
`;
/////////////slideLeft/////////
const slideInLeftAnimationKeyframes = keyframes`${slideInLeft}`;
const slideInLeftAnimation = css`
  animation: 0.4s ${slideInLeftAnimationKeyframes};
`;

/////////modalAnimation//////
const modalAnimation = slideInUpAnimation;
const modalOutAnimation = slideOutDownAnimation;
////////hoverAnimation//////
const hoverBigAnimation = css`
  transition: all 0.2s ease-out;
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
`;
const hoverSmallAnimation = css`
  transition: all 0.1s ease-out;
  transform: translateY(-2px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 1px 2px rgba(0, 0, 0, 0.24);
`;
////////////////////////////////
const hoverLineState1_inBefore = css`
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  right: 0;
  bottom: -0.5rem;
  background-color: var(--color-primary);
  transition: all 0.3s ease;
`;
const hoverLineState2_inBefore = css`
  width: 100%;
  left: 0;
`;
export {
  cardAnimation,
  fadeinAnimation,
  slideInUpAnimation,
  slideInDownAnimation,
  slideInRightAnimation,
  slideInLeftAnimation,
  modalAnimation,
  modalOutAnimation,
  hoverBigAnimation,
  hoverSmallAnimation,
  bounceInAnimation,
  //
  hoverLineState1_inBefore,
  hoverLineState2_inBefore,
};
