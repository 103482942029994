import styled from 'styled-components/macro';
import useSmartBanner from '../../SmartBanner/Hooks/useSmartBanner';
import useUser from '../../../hooks/useUser';
import { UserRoles } from '../../../Interfaces';
import SmartBanner from '../../SmartBanner';
import ChannelContent from './ChannelContent';
import NotLoginContent from './NotLoginContent';
import TalentContent from './TalentContent';

interface Props {
  onlyLogo?: boolean;
}
const DesktopMenu: React.FC<Props> = ({ onlyLogo = false }) => {
  const { role, talentInfo, channelInfo } = useUser();
  const { smartBannerVariant, hasTeammate, userNumbers } = useSmartBanner({ talentInfo, channelInfo });

  function getSmartContent() {
    if (role === UserRoles.CHANNEL)
      return <ChannelContent onlyLogo={onlyLogo} hasTeammate={hasTeammate}/>;
    if (role === UserRoles.TALENT) return <TalentContent onlyLogo={onlyLogo} />;
    return <NotLoginContent onlyLogo={onlyLogo} />;
  }
  return (
    <Container>
      <Wrapper>{getSmartContent()}</Wrapper>
      <SmartBanner variant={smartBannerVariant} teammateNumbers={userNumbers}/>
    </Container>
  );
};
export default DesktopMenu;
const Container = styled.section`
  width: 100%;
  z-index: 10;
  height: max-content;
`;
const Wrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4rem;
  background: var(--color-menu);
  /* menu shadow */
  box-shadow: 0px 3px 8px var(--color-menu-shadow);
`;
