import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import logoPlaceholder from '../../Assests/Images/logoPlaceholder';
import HireMeImg from '../../Assests/Images/talent-badges/Hireme.svg';
import { deviceMin } from '../../consts/device';

interface Props extends HTMLAttributes<HTMLDivElement> {
  logoUrl?: string | null | File;
  alt: string | undefined;
  linkTo?: string;
  hireMe?: boolean;
}
const UserAvatar: React.FC<Props> = ({
  logoUrl,
  alt,
  className,
  linkTo,
  hireMe,
  ...props
}) => {
  //typeGurd
  if (logoUrl instanceof File) return null;

  return (
    <>
      {linkTo ? (
        <Link to={linkTo} className={className}>
          <LogoWrapper>
            <Img src={logoUrl || logoPlaceholder} alt={alt} loading='lazy' />
            {hireMe && <ForGround src={HireMeImg} alt='hireme'/>}
          </LogoWrapper>
        </Link>
      ) : (
        <LogoWrapper {...props} className={className}>
          <Img src={logoUrl || logoPlaceholder} alt={alt} loading='lazy' />
          {hireMe && <ForGround src={HireMeImg} alt='hireme'/>}
        </LogoWrapper>
      )}
    </>
  );
};
export default UserAvatar;
const LogoWrapper = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  position: relative;
  //desktop
  @media ${deviceMin.tabletS} {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
const Img = styled.img`
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: cover;
`;
const ForGround = styled.img`
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: cover;
  position: absolute;
  inset: 0;
`;
