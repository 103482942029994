import useUser from '../../../hooks/useUser';

import {
  LogoutContainer,
  LogoutTitle,
  MenuItem,
  StyledChannelThumbnail,
  ThumbnailContainer,
} from './StyledComponent';
import { FiLogOut, FiUserPlus } from 'react-icons/fi';
import { YtjobsIcons } from '../../../Interfaces';
import { TeammatesLink, ArrowRight, MobileButtonContainer, LogoutContainer3, MobileOtherAccountsContainer } from '../DesktopMenu/StyledComponents';
import { isACompany } from '../../../Utils/channel';
import { AddChannelAccountPopop } from '../AddChannelAccountPopop';
import { useState } from 'react';
import { useChannelAccounts } from '../Utils/useChannelAccounts';

interface Props {
  closeHandler: () => void;
  hasTeammate: boolean;
}

const ChannelContent: React.FC<Props> = ({ closeHandler, hasTeammate }) => {
  const { channelInfo } = useUser();
  const [addPopup, setAddPopup] = useState(false);
  const { logout, accountsList, switchTo } = useChannelAccounts(addPopup);

  return (
    <>
      <ThumbnailContainer>
        <StyledChannelThumbnail
          variant='TYPE1'
          avatar={channelInfo?.avatar || null}
          isCompany={isACompany(channelInfo)}
          abvSubscribers={channelInfo?.abvSubscribers || ''}
          channelName={channelInfo?.name}
          isClickable={true}
          channelId={channelInfo?.id}
        />
      </ThumbnailContainer>

      {!!accountsList?.length && (
        <MobileOtherAccountsContainer>
          <LogoutContainer3 style={{ paddingTop: 0 }}>
            <LogoutTitle style={{ margin: 0 }}>Other accounts:</LogoutTitle>
          </LogoutContainer3>
          {accountsList.map((al, i) => (
            <LogoutContainer3
              key={al.id}
              hasHover={true}
              onClick={() => {
                switchTo(al);
                document.location.reload();
              }}
              style={{ paddingTop: 1, paddingBottom: i === (accountsList.length - 1) ? 1 : 8 }}
            >
              <img src={al.avatar} alt={al.name} />
              <LogoutTitle>{al.name}</LogoutTitle>
            </LogoutContainer3>
          ))}
        </MobileOtherAccountsContainer>
      )}

      {/* <PrimaryItem to={"/job/create"}>Post a Job</PrimaryItem> */}
      <MenuItem
        to={'/'}
        onClick={() => {
          closeHandler();
        }}
      >
        Home
      </MenuItem>
      <MenuItem
        to={'/job/search/all_categories'}
        onClick={() => {
          closeHandler();
        }}
      >
        Jobs
      </MenuItem>
      <MenuItem
        to={'/talent/search/all_categories?page=1'}
        onClick={() => {
          closeHandler();
        }}
      >
        Talent
      </MenuItem>
      <MenuItem
        to={'/feed'}
        onClick={() => {
          closeHandler();
        }}
      >
        Feed
      </MenuItem>
      <MenuItem
        to={'/channel/referral'}
        onClick={() => {
          closeHandler();
        }}
      >
        Referral
      </MenuItem>
      {!!channelInfo?.canSearchAdvanced && (
        <MenuItem
          to={'/channel/discovery'}
          onClick={() => {
            closeHandler();
          }}
        >
          {'Discover'} <span style={{ fontSize: 12 }}>(beta)</span>
        </MenuItem>
      )}
      {/* <MenuItem
        to={'/channel/pricing'}
        onClick={() => {
          closeHandler();
        }}
      >
        Pricing
      </MenuItem> */}
      <MenuItem
        active={true}
        to={'/job/create'}
        onClick={() => {
          closeHandler();
        }}
      >
        Post a job
      </MenuItem>
      {
        hasTeammate && <MobileButtonContainer>
          <TeammatesLink to={'/review'}>Talent Review <ArrowRight variant={YtjobsIcons.arrowRight} /></TeammatesLink>
        </MobileButtonContainer>
      }


      <LogoutContainer
        onClick={() => {
          setAddPopup(true);
        }}
      >
        <FiUserPlus />
        <LogoutTitle>Add Account</LogoutTitle>
      </LogoutContainer>



      <LogoutContainer
        style={{ borderTop: 0 }}
        onClick={logout}
      >
        <FiLogOut />
        <LogoutTitle>Logout</LogoutTitle>
      </LogoutContainer>



      {addPopup && (
        <AddChannelAccountPopop
          close={() => {
            setAddPopup(false);
          }}
        />
      )}
    </>
  );
};
export default ChannelContent;
