import { useLocation } from "react-router-dom";
import { PageContentContainer as Container } from "../../Layout";
import PrimaryItem from "./PrimaryItem";
import SecondaryItem from "./SecondaryItem";
import { PagesWrapper, StyledLogo, SignIn, SignUp } from "./StyledComponents";
import { ToggleTheme } from "../../../Elements/ToggleTheme";

interface Props {
  onlyLogo: boolean;
}
const NotLoginContent: React.FC<Props> = ({ onlyLogo }) => {
  const location = useLocation();
  return (
    <Container style={{ height: "100%" }}>
      <PagesWrapper>
        <StyledLogo />
        {!onlyLogo && (
          <>
            <PrimaryItem
              destination={"/talent/signup"}
              data-testid="menu-channel-content"
              label="Join as Talent"
            />

            <SecondaryItem destination="/" isActive={location.pathname === '/'} label="Home" />
            <SecondaryItem destination="/job/search/all_categories" label="Jobs" />
            <SecondaryItem destination='/talent/search/all_categories?page=1' label="Talent" />
            <SecondaryItem
              destination="/login"
              isActive={location.pathname === "/job/create"}
              label="Post a job"
            />
            <SecondaryItem destination="/feed" label="Feed" />
            <div style={{flex: 1}} />
            <ToggleTheme />
            <SignIn destination="/login?type=t" label="Login" />
            <SignUp destination="/signup" label="Sign up"/>
          </>
        )}
      </PagesWrapper>
    </Container>
  );
};
export default NotLoginContent;
