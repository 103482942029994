export const siteMetadata = {
  defaultTitle: `YT Jobs | The YouTube Creators Job Board`,
  defaultDescription: `Whether a talented YouTube professional or creator, here you can find your dream job or next ideal teammate.`,
  siteUrl: `https://ytjobs.co`,
  keywords:
    "YT Jobs, Youtube, Youtuber, youtube professional, youtube creator, youtube job board",
  legalName: "YT Jobs",
  email: "support@ytjobs.co",
};

export const pagesMetadata = {
  login: {
    title: `Login to YT Jobs`,
    description: `Login to YT Jobs by express link...`,
    keywords: `YT Jobs, Youtube, login, express link, Youtuber`,
  },
  talentSignup: {
    title: `Join YT Jobs!`,
    description: `Sign up on YT Jobs as a talent to apply for many jobs posted by YouTubers...`,
    keywords: `YT Jobs, Youtube, sign up, join, Youtube creator`,
  },
  channelSignup: {
    title: `Start Hiring on YT Jobs!`,
    description: `Start Hiring the best-talented professionals for your YouTube channel...`,
    keywords: `YT Jobs, Youtube, Youtuber, find teammate, post job, job posting`,
  },
  talentSearch: {
    title: `The Best YouTube Professionals (++) | YT Jobs`,
    description: `See the most professional persons on youtube skills, Including Video Editors, Thumbnail Designers, Youtube Strategists, and so on.`,
    keywords: `YT Jobs, Youtube, Youtuber, Youtube professional, thumbnail designer, video editor, creative director, youtube strategist, channel manager, scriptwriter, video producer, Youtube job board`,
  },
  jobSearch: {
    title: `Explore Youtube Jobs | YT Jobs (++)`,
    description: `See the most exciting YouTube job opportunities, apply to them, and be a member of the most famous youtube channels with millions of subscribers.`,
    keywords: `YT Jobs, Youtube, Youtuber, Youtube job, Youtube creator, Youtube job board, full-time, part-time, paid position, apply for job`,
  },
  homePage: {
    title: `YT Jobs | The YouTube Creators Job Board`,
    description: `Whether a talented YouTube professional or creator, here you can find your dream job or next ideal teammate.`,
    keywords: `YT Jobs, Youtube, Youtuber, youtube professional, youtube creator, youtube job board`,
  },
};
