import { MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';
import { ScrollStyle } from '.';
import { deviceMin } from '../consts/device';
import { modalAnimation } from './Animation';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.24);
  z-index: 100;
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const ModalGrayBlank = styled.div`
  height: 100%;
  background-color: var(--color-modal-backdrop);
  // desktop
  @media ${deviceMin.tabletS} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const ModalContent = styled.div<{ position?: 'CENTER' | 'BOTTOM' }>`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  height: auto;
  max-height: 80%;
  padding: 1rem 1rem 3rem;
  background: var(--color-bg1);
  box-shadow: 0px -3px 8px rgba(8, 8, 43, 0.06);
  border-radius: 16px 16px 0px 0px;
  position: absolute;
  bottom: 0;
  ${ScrollStyle}
  ${modalAnimation}
  @media ${deviceMin.tabletS} {
    padding: 1rem;
    padding-bottom: 1rem;
  }

  //
  ${({ position }) =>
    position === 'CENTER' &&
    `
        width:90%;
        bottom:unset;
        left: 5%;
        top: 20%;
        height: 60%;
        overflow: auto;
        border-radius: 1rem;
      `}

  // desktop
  @media ${deviceMin.tabletS} {
    margin: auto;
    padding: 5rem 3rem 2.5rem;
    border-radius: 16px;
    width: 455px;
    max-width: 80%;
    z-index: 110;
    position: relative;
  }
`;
const ModalMdClose = styled(MdClose)`
  flex-shrink: 0;
  // desktop
  @media ${deviceMin.tabletS} {
    margin-left: auto;
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: initial;
    width: 1rem;
    height: 1rem;
  }
  display: none;
`;
const ModalGrayDash = styled.div`
  flex-shrink: 0;
  // desktop
  @media ${deviceMin.tabletS} {
    display: none;
  }
  width: 2rem;
  height: 3px;
  background: var(--color-gray4);
  border-radius: 16px;
  margin: 0.5rem auto 1.25rem auto;
`;

const ModalContentBody = styled.div`
  ${ScrollStyle}

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: var(--color-gray2);
  position: relative;
`;
const ModalTitle = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--color-gray1);
  @media ${deviceMin.tabletS} {
    font-size: 22px;
    line-height: 33px;
  }
`;
const ModalSubtitle = styled.h5`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 1rem;
  color: var(--color-gray2);
  @media ${deviceMin.tabletS} {
    font-size: 20px;
    line-height: 31px;
  }
`;
export {
  ModalContainer,
  ModalGrayDash,
  ModalMdClose,
  ModalContent,
  ModalGrayBlank,
  ModalWrapper,
  ModalContentBody,
  ModalTitle,
  ModalSubtitle,
};
